<div class="accountSummaryContainer" xmlns="http://www.w3.org/1999/html">
  <div class="modal-header justify-content-between">
    <span></span>
    <div class="">
      <label class="typography__h3">{{"accounts"| translate: "accountDetail"}}</label>
    </div>
    <div class="close-modal" (click)="back()">
      <span class="helper"></span>
      <img src="assets/images/close.svg" class="display__end"/>
    </div>
  </div>
  <div class="accountSummaryContainer__DetailsGrid">
    <div class="accountSummaryContainer__SummaryColumn modal-body">
      <div class="row">
        <div class="col-sm-3 form-group">
          <label for="accountId" class="inputField__label">{{"accounts" | translate: "accountID"}}</label>
          <input type="text" disabled name="accountId" id="accountId"
                 [(ngModel)]="account.bbaccountid">
        </div>
        <div class="offset-2 col-sm-7 d-flex">
          <div class="col-sm-4">
            <label for="status" class="inputField__label">{{"accounts" | translate: "status"}}</label>
            <input type="text" id="status" disabled [(ngModel)]="account.statusdisplay">
          </div>
          <div class="col-sm-8 py-2 d-flex accountSummaryContainer__btnStatusContainer m-left-12">
            <button type="button" class="btn btn__primary btn__regular
                            accountSummaryContainer__btnStatusContainer_btn1 btn-min-width"
                    (click)="updateAccountStatus()"
                    *ngIf="account.status == 'C' && items.length">
              {{"accountSummary" | translate:'accountOpen'}}
            </button>
            <button type="button" class="btn btn__primary btn__regular
                            accountSummaryContainer__btnStatusContainer_btn1 btn-min-width"
                    (click)="updateAccountStatus()"
                    *ngIf="account.status == 'O'">
              {{"accountSummary" | translate:'accountClose'}}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5 accountSummaryContainer__clientContainer d-flex">
          <div class="col-sm-8 form-group">
            <label for="invoiceTo" class="inputField__label">
              {{"accountSummary" | translate: "invoiceTo"}}
            </label>
            <input type="text" id="invoiceTo" readonly (click)="editClient()" [(ngModel)]="client.fullname">
          </div>
          <div class="col-sm-2 clientBtnContainer viewClientContainer">
            <button class="btn__icon btn__regular-icon--active-green editClientBtn" type="button" (click)="editClient()"
                    ngbTooltip="{{'clientAdd'|translate:'clientEdit'}}"
                    tooltipClass="tooltip__container">
              <svg class="ic ic_person">
                <use href="assets/images/icons/sprite.svg#ic_person"/>
              </svg>
            </button>
          </div>
          <div class="col-sm-2 clientBtnContainer">
            <button class="btn__icon btn__regular-icon--active-blue changeClientBtn" type="button"
                    (click)="changeClient()"
                    *ngIf="account.status !== 'C'" ngbTooltip="{{'bookingSummary'|translate:'clientChange'}}"
                    tooltipClass="tooltip__container">
              <svg class="ic ic_add_person">
                <use href="assets/images/icons/sprite.svg#ic_add_person"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="col-sm-7 accountSummaryContainer__clientContainer">
          <div class="col-sm-4">
            <label for="proForma" class="inputField__label">{{"accountSummary" | translate: "proForma"}}</label>
            <input type="text" id="proForma" disabled readonly [(ngModel)]="account.proformano">
          </div>

          <div class="col-sm-9 invoiceBtnContainer m-left-12" *ngIf="pdfViewerActive">
            <button type="button" class="btn btn__secondary btn__regular btn__invoice" (click)="createProForma()"
                    *ngIf="invoiceTemplatePreference === constants.PDF || invoiceTemplatePreference === constants.NOSELECTION">
              {{"accountSummary" | translate: "viewInvoice"}}
            </button>
            <button type="button" *media="breakpoints.TABLET"
                    class="btn btn__primary btn__regular btn__invoice btn__minWidth" (click)="downloadProForma()"
                    [hidden]="invoiceTemplatePreference === constants.PDF">
              <svg class="ic ic_file_excel">
                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
              </svg>
            </button>
            <button type="button" *media="breakpoints.DESKTOP" class="btn btn__primary btn__regular btn__invoice"
                    (click)="downloadProForma()" [hidden]="invoiceTemplatePreference === constants.PDF">
              {{"accountSummary" | translate: "generate"}}
              <svg class="ic ic_file_excel">
                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
              </svg>
            </button>
            <app-tool-tip class="m-left-8" *media="breakpoints.DESKTOP" type="accounting-documents"
                          [hidden]="invoiceTemplatePreference !== constants.NOSELECTION || !pdfViewerActive"></app-tool-tip>
          </div>
          <div class="d-flex m-left-12 mt-2 flex-wrap-reverse gap-1" *ngIf="!pdfViewerActive">
            <button type="button" class="btn btn__primary btn__regular btn-min-width" (click)="downloadProForma()">
              {{"general" | translate:'generate'}}
              <svg class="ic ic_file_excel">
                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5 right-align">
          <label for="reference" class="inputField__label">{{"accounts" | translate: "reference"}}</label>
          <input type="text" id="reference" [(ngModel)]="account.reference" maxLength="30">
        </div>
        <div class="col-sm-7 accountSummaryContainer__clientContainer">
          <div class="col-sm-4">
            <label
              class="accountSummaryContainer__invoice_label  inputField__label">{{"accountSummary" | translate: "invoiceNo"}}</label>
            <input type="text" readonly disabled [ngModel]="account.invoiceno | invoicedNo"
                   (ngModelChange)="account.invoiceno=$event">
          </div>

          <div class="col-sm-9 invoiceBtnContainer m-left-12" *ngIf="pdfViewerActive">
            <button type="button" class="btn__secondary btn btn__regular btn__invoice " (click)="createInvoice(true)"
                    *ngIf="items.length !== 0 && (invoiceTemplatePreference === constants.PDF || invoiceTemplatePreference === constants.NOSELECTION)">
              {{"accountSummary" | translate:'finalInvoice'}}
            </button>

            <button type="button" *media="breakpoints.TABLET"
                    class="btn__primary btn btn__regular btn__invoice btn__minWidth" (click)="createInvoice(false)"
                    [hidden]="(items.length === 0 || account.invoiceno) || invoiceTemplatePreference === constants.PDF">
              <svg class="ic ic_file_excel">
                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
              </svg>
            </button>

            <button type="button" *media="breakpoints.DESKTOP" class="btn__primary btn btn__regular btn__invoice "
                    (click)="createInvoice(false)"
                    [hidden]="(items.length === 0 || account.invoiceno) || invoiceTemplatePreference === constants.PDF">
              {{"accountSummary" | translate:'generate'}}
              <svg class="ic ic_file_excel">
                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
              </svg>
            </button>

            <button type="button" *media="breakpoints.TABLET"
                    class="btn__primary btn__regular btn btn__print btn__minWidth"
                    (click)="invoiceReprintWarning(false)"
                    [hidden]="!account.invoiceno || invoiceTemplatePreference === constants.PDF">
              <svg class="ic ic_file_excel">
                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
              </svg>
            </button>

            <button type="button" *media="breakpoints.DESKTOP" class="btn__primary btn__regular btn btn__print"
                    (click)="invoiceReprintWarning(false)"
                    [hidden]="!account.invoiceno || invoiceTemplatePreference === constants.PDF">
              {{"accountSummary" | translate:'print'}}
              <svg class="ic ic_file_excel">
                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
              </svg>
            </button>
          </div>

          <div class="col-sm-9 invoiceBtnContainer m-left-12" *ngIf="!pdfViewerActive">
            <button type="button" class="btn__primary btn btn__regular btn__invoice" (click)="createInvoice(false)"
                    *ngIf="items.length !== 0 && !account.invoiceno">
              {{"general" | translate:'generate'}}
              <svg class="ic ic_file_excel">
                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
              </svg>
            </button>
            <button type="button" class="btn__primary btn__regular btn btn__invoice"
                    (click)="invoiceReprintWarning(false)"
                    *ngIf="account.invoiceno">
              {{"accountSummary" | translate:'print'}}
              <svg class="ic ic_file_excel">
                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
              </svg>
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="accountSummaryContainer__ItemsColumn modal-body">
      <div class="row">
        <div class="col-sm-4 bottom_layout_container">
          <label class="accountSummaryContainer__ItemsColumn__header">
            {{"accountSummary" | translate: "itemsLabel"}}
          </label>
        </div>

        <div class="col-sm-8 d-flex py-3 justify-content-end">
          <app-tap-n-pay
            *ngIf="(isTapNpayEnabled$ | async) && !!getBookingIdFromItems(items)"
            [classes]="'max-h-sm-48'"
            [details]="{
                          bbId: account.bbid,
                          amount: 0,
                          bookingId: getBookingIdFromItems(items),
                          mode: 'payment',
                          refundPaymentId: '',
                          currencyCode: getCurrency()
                        }"
            [bookingDetails]="{
                          amountDue: 0,
                          accommodationTotal: account.accomtotal,
                          extraTotal: account.extratotal,
                          paymentsTotal: account.paymenttotal,
                          clientFullName: account?.details?.clientname,
                          fromDate: '',
                          toDate: ''
                        }"
          ></app-tap-n-pay>
          <button type="button" class="btn btn__primary btn__regular display__end addPaymentButton btn-min-width-lg"
                  (click)="addPayment()">
            {{"accountSummary" | translate: "addPayment"}}
          </button>
          <button type="button" class="btn btn__primary btn__regular display__end mx-0" (click)="generateCcLink()">
            {{"accountSummary" | translate: "creditLink"}}
          </button>
        </div>
      </div>
      <div class="table_container__scrollable table_container__scrollable__small">
        <table class="table__bordered_style">
          <thead>
          <tr>
            <th scope="col" class="typography__p">{{"accountSummary" | translate: "date"}}</th>
            <th scope="col" class="typography__p">{{"accountSummary" | translate: "description"}}</th>
            <th scope="col" class="typography__p text-right">{{"accountSummary" | translate: "debit"}}</th>
            <th scope="col" class="typography__p text-right">{{"accountSummary" | translate: "credit"}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of items; let i=index;"
              (click)="viewItem(item)"
              ngbTooltip="{{getToolTipForAccountItem(item)}}"
              tooltipClass="tooltip__container">
            <td class="typography__p">{{getLocalDate(item.date)}}</td>
            <td class="typography__p">{{item.description}}</td>
            <td class="typography__p currencyTextAlignRight">{{getAmount(item.debit) | currency}}</td>
            <td class="typography__p currencyTextAlignRight">{{getAmount(item.credit) | currency}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="accountSummaryContainer__TotalsColumn modal-body">
      <div class="accountSummaryContainer__TotalsGrid">
        <div>
          <div class="row">
            <label class="typography__h4">{{"accountSummary" | translate: "totals"}}</label>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="inputField__label">{{"accountSummary" | translate: "accommodation"}}</label>
              <input type="text" class="accountSummaryTotalsText" disabled [ngModel]="account.accomtotal | currency"
                     (ngModelChange)="account.accomtotal=$event">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="inputField__label">{{"accountSummary" | translate: "extras"}}</label>
              <input type="text" class="accountSummaryTotalsText" disabled
                     [ngModel]="account.extratotal | currency" (ngModelChange)="account.extratotal=$event">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="inputField__label">{{"accountSummary" | translate: "payment"}}</label>
              <input type="text" class="accountSummaryTotalsText" disabled
                     [ngModel]="account.paymenttotal | currency" (ngModelChange)="account.paymenttotal=$event">
            </div>
          </div>
        </div>
        <div class="accountSummaryContainer__TotalsGrid_Outstanding">
          <div class="row">
            <div class="col-sm-12">
              <label class="inputField__label">{{"accountSummary" | translate: "outstanding"}}</label>
              <input type="text" class="accountSummaryTotalsText" disabled
                     [ngModel]="account.outstanding | currency" (ngModelChange)="account.outstanding=$event">
              <button type="button" class="btn btn__secondary btn__regular account-summary__btn-checkout"
                      (click)="payOnCheckOut()">
                {{"general" | translate: "checkOut"}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="col-sm-2">
      <button class="btn__regular btn btn__primary"
              (click)="openStatementModal()">{{"accountSummary"| translate: "statement"}}
      </button>
    </div>
    <div class=" offset-8 col-sm-2">
      <button type="button" class="btn btn__primary btn__regular display__end btn__green"
              (click)="saveToDB()">{{"general"| translate: "save"}}</button>
    </div>
  </div>
</div>
