import {Component, Input, OnInit} from '@angular/core';
import {CalendarComponent} from 'src/app/pages/calendar/calendar.component';
import {BookingStatus} from 'src/app/service/models/enum/booking-status.enum';

@Component({
  selector: 'app-guest-data-card',
  templateUrl: './guest-data-card.component.html',
  styleUrls: ['./guest-data-card.component.scss']
})
export class GuestDataCardComponent extends CalendarComponent implements OnInit {

  @Input() booking;
  @Input() room;
  @Input() popover;

  ngOnInit(): void { }

  getMailTo(booking) {
    return `mailto:${booking.madebyemail}?subject=${booking.bookingId}`;
  }

  getStatus(status) {
    let statusValue = Object.keys(BookingStatus).find((key) => {
      return BookingStatus[key] === status;
    })
    return this.translateService.translate('bookingStatus', statusValue);
  }

  getStatusColor(booking, room) {
    return this.getBookingStatusColour(booking.status, this.getRoomBooking(booking.rooms, room).checkedOut, this.getRoomBooking(booking.rooms, room).checkedOut);
  }
}