<div class="account-info-container d-flex flex-column m-2">
    <div class="account-section d-flex">
        <div class="flex-1 account-info-column m-1">
            <p class="fw-semi-bold px-2"> Account Info  </p>
            <div class="buttons">
                <cui-button ngbTooltip="{{'bookingSummary'|translate:'viewAccountDetail'}}" tooltipClass="tooltip__container" (click)="viewAccount()" mode="primary" classes="mt-1 p-1 p-sm-2 h-40"> <span class="fs-14"> {{'bookingSummary'|translate:'viewAccount'}} </span>   </cui-button>
                <cui-button (click)="viewRates()" mode="primary" classes="mt-1 p-1 p-sm-2 h-40"> <div class="fs-14"> {{'bookingSummary'|translate:'viewRates'}} </div> </cui-button>
                <cui-button (click)="checkout()" mode="primary" classes="mt-1 p-1 p-sm-2 h-40"> <span class="fs-14"> {{'bookingSummary'|translate:'checkOut'}} </span> </cui-button>
                <ng-container class="invalid-cc-button"  *ngIf="reportInvalidCCBookingStatus">
                    <cui-button *ngIf="reportInvalidCCBookingStatus === ReportBookingStatus.AVAILABLE" (click)="invalidCC()" type="tonal" classes="m-1 mb-sm-0 p-3 p-sm-2 h-40" [disabled]="invalidCCReported">
                        <span class="fs-14 fw-semi-bold"> {{'bookingSummary'|translate: (invalidCCReported ? 'badCCReported': 'invalidCC')}} </span>
                    </cui-button>
                    <cui-button *ngIf="reportInvalidCCBookingStatus === ReportBookingStatus.REPORTED" type="outlined" [disabled]="true" classes="reported-button m-1 mb-sm-0 p-3 p-sm-2">
                        <span class="fs-14"> {{'bookingSummary'|translate: 'badCCReported'}} </span>
                    </cui-button>
                </ng-container>
                <ng-container class="no-show-button"  *ngIf="reportNoShowStatus">
                    <cui-button *ngIf="reportNoShowStatus === ReportBookingStatus.AVAILABLE" (click)="noShow()" type="tonal" classes="m-1 mb-sm-0 p-3 p-sm-2 h-40" [disabled]="noShowReported">
                        <span class="fs-14 fw-semi-bold"> {{'bookingSummary'|translate: (noShowReported ? 'noShowReported': 'noShow')}} </span>
                    </cui-button>
                    <cui-button *ngIf="reportNoShowStatus === ReportBookingStatus.REPORTED" type="outlined" [disabled]="true" classes="reported-button m-1 mb-sm-0 p-3 p-sm-2 h-40">
                        <span class="fs-14"> {{'bookingSummary'|translate: 'noShowReported'}} <br> {{'bookingSummary'|translate: typeReported}} </span>
                    </cui-button>
                </ng-container>
            </div>
        </div>
        <div class="flex-1 totals-column m-1 p-left-12">
            <p class="fw-semi-bold"> Totals </p>
            <div class="fs-14 d-flex flex-column flex-sm-row justify-content-sm-between">
                <div> {{'bookingSummary'|translate:'accommodation'}}: </div>
                <div> {{formatCurrency(bookingSummaryObject.bookingaccount.accomodationtotal)}} </div>
            </div>
            <div class="fs-14 d-flex fs-sm flex-column flex-sm-row justify-content-sm-between">
                <div> {{'bookingSummary'|translate:'extras'}}: </div>
                <div> {{formatCurrency(bookingSummaryObject.bookingaccount.extratotal)}} </div>
            </div>
            <div class="fs-14 d-flex fs-sm flex-column flex-sm-row justify-content-sm-between">
                <div> {{'bookingSummary'|translate:'payments'}}: </div>
                <div> {{formatCurrency(bookingSummaryObject.bookingaccount.payments)}} </div>
            </div>
        </div>
    </div>
    <div *ngIf="(isTapNpayEnabled$ | async)">
        <hr>
        <div class="d-flex">
            <div class="flex-1 m-1">
                <div class="buttons">
                    <app-tap-n-pay
                            [classes]="'p-1 p-sm-2 h-40 ml-0'"
                            [details]="{
                              bbId: bookingSummaryObject.booking.bbid,
                              amount: 0,
                              bookingId: bookingSummaryObject.booking.bookingid,
                              mode: 'payment',
                              refundPaymentId: '',
                              currencyCode: getCurrency()
                            }"
                            [bookingDetails]="{
                              amountDue: 0,
                              accommodationTotal: bookingSummaryObject.bookingaccount.accomodationtotal,
                              extraTotal: bookingSummaryObject.bookingaccount.extratotal,
                              paymentsTotal: bookingSummaryObject.bookingaccount.payments,
                              clientFullName: bookingSummaryObject.madebyclient?.fullname,
                              fromDate: bookingSummaryObject.booking.fromdate,
                              toDate: bookingSummaryObject.booking.todate
                            }"
                    ></app-tap-n-pay>
                </div>
            </div>
            <div class="flex-1 totals-column m-1 p-left-12">
                <!-- PLACEHOLDER DIV: DO NOT DELETE -->
            </div>
        </div>
    </div>
    <hr>
    <div class="deposit-section d-flex">
        <div class="flex-1 m-1">
            <cui-button (click)="calculateDeposit()" mode="primary" classes="mt-1 p-1 p-sm-2 h-40"> <span class="fs-14"> {{'bookingSummary'|translate:'depositCalc'}} </span>  </cui-button>
        </div>
        <div class="flex-1 m-1 mt-2 p-left-12">
            <div class="fs-14 d-flex flex-column flex-sm-row justify-content-sm-between gap-3">
                <div class="lh-40 d-none d-sm-block"> {{'bookingSummary'|translate:'deposit'}}: </div>
                <div class="d-none d-sm-block">
                    <form [formGroup]="bookingSummaryForm">
                        <cui-input
                                class="w-100 mt-1"
                                formControlName="displaydeposittotal"
                                (onBlur)="updateDeposit.emit()"
                                [classes]="'text-align-right'"
                                [sizing]="'sm'"
                        ></cui-input>
                    </form>
                </div>
                <div class="d-block d-sm-none ">
                    <form [formGroup]="bookingSummaryForm">
                        <cui-input
                                class="w-100 mt-1"
                                formControlName="displaydeposittotal"
                                (onBlur)="updateDeposit.emit()"
                                [label]="'Deposit:'"
                                [sizing]="'sm'"
                        ></cui-input>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="status-section d-flex justify-content-center justify-content-sm-start">
        <div class="w-100 max-w-sm-70">
            <cui-button (click)="viewStatusAndHistory()" mode="secondary" type="tonal" classes="mb-1 mt-0 p-2 fs-14">
                <div class="d-flex">
                    <div class="fw-semi-bold text-nowrap">
                        <span class="d-none d-sm-inline"> View </span>
                        Payment Status & Booking History
                    </div>
                    <div class="d-none fw-semi-bold ml-1 lh-18 fs-18 p-0 d-sm-block"> › </div>
                </div>
            </cui-button>
        </div>
    </div>
    <div class="d-flex m-2 flex-column flex-sm-row justify-content-sm-between">
        <div class="mt-1 fs-14">
            <span class="fw-bold">{{"bookingSummary" | translate:'entered'}}</span>
            {{getLocalDateTime(bookingSummaryObject.booking?.bookingdate)}}
        </div>
        <div class="mt-1 fs-14">
            <span class="fw-bold">{{"bookingSummary" | translate:'type'}}</span>
            {{bookingSummaryObject.bookingtype}}
        </div>
    </div>
</div>
