import {Component, Input} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {tapNpayModalMessageOptions} from "../../tap-n-pay.class";
import {TapNPayModalMessageComponent} from "../tap-n-pay-modal-message/tap-n-pay-modal-message.component"

@Component({
  selector: 'app-tap-n-pay-modal-container',
  templateUrl: './tap-n-pay-modal-container.component.html',
  styleUrls: ['./tap-n-pay-modal-container.component.scss']
})
export class TapNpayModalContainerComponent {

  @Input() confirmBeforeClose: boolean = false;
  @Input() title = 'Tap & Pay';
  @Input() fullWidth = false;

  constructor(
    private activeModal: NgbActiveModal,
    private modal: NgbModal
  ) { }

  close () {
    if (this.confirmBeforeClose) {
      const confirmModal = this.modal.open(TapNPayModalMessageComponent, tapNpayModalMessageOptions);
      confirmModal.componentInstance.title = 'Do you really want to close Tap & Pay?';
      confirmModal.componentInstance.message = 'The transaction will continue to be processed in the background.';
      confirmModal.componentInstance.approveText = 'Yes, close it';
      confirmModal.componentInstance.rejectText = 'No, go back';

      confirmModal.closed.subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }
}
