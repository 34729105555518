import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {TapNpayService} from "../../tap-n-pay.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-tap-n-pay-modal-amount',
    templateUrl: './tap-n-pay-modal-amount.component.html',
    styleUrls: ['./tap-n-pay-modal-amount.component.scss']
})
export class TapNpayModalAmountComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    errorMessage: {heading: string, subtitle: string} | null = {heading: '', subtitle: ''};
    showErrorMessage = false;

    currentDate: Date = new Date();

    maxAmount = 1000000;
    minAmount = 10;

    amount: number | string | null = 0;
    amountDue: number | string = 0;
    amountControl = new FormControl(this.amount, [Validators.required]);

    bookingID = 0;
    clientFullName: string | undefined = '';
    fromDate: Date | string = '';
    toDate: Date | string = '';

    dateFormat = 'EEEE d MMMM y';

    currencyCode: string | undefined;

    constructor(
        public activeModal: NgbActiveModal,
        private tapNpayService: TapNpayService
    ) {
      this.dateFormat = this.tapNpayService.paymentDetails?.value.dateFormat || this.dateFormat;
      this.currencyCode = tapNpayService.paymentDetails?.value.currencyCode;
    }

    ngOnInit() {
      const { paymentDetails } = this.tapNpayService;
      const { bookingDetails } = this.tapNpayService;
      const { amount, bookingId } = paymentDetails.value;
      const {toDate, fromDate, clientFullName, amountDue} = bookingDetails.value;

      this.bookingID = bookingId;
      this.toDate = toDate;
      this.fromDate = fromDate;
      this.clientFullName = clientFullName;
      this.amountDue = amountDue;


      if (amount > this.minAmount) {
        this.amountControl.setValue(amount);
      } else if (this.amountDue < 0) {
        this.amountControl.setValue(0);
      } else {
        this.amountControl.setValue(this.amountDue);
      }

      this.amountControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((amount) => {
        this.amount = amount;

        const isAmountAboveBooking =  (this.convertCurrencyToNumber(this.amount) < this.maxAmount) && (this.convertCurrencyToNumber(this.amount) > this.amountDue);
        const amountAboveBookingHeading = 'Amount Exceeds Booking Value';
        const amountAboveBookingSubtitle = 'Warning: The pre-authorised amount exceeds the total booking value, are you sure you want to proceed?';

        const isAmountAboveMaximum = this.convertCurrencyToNumber(this.amount) >= this.maxAmount;
        const amountAboveMaximumHeading = 'Amount Exceeds Maximum Value';
        const amountAboveMaximumSubtitle = 'Please enter a lower amount';

        this.errorMessage = isAmountAboveBooking ? {heading: amountAboveBookingHeading, subtitle: amountAboveBookingSubtitle} : this.errorMessage;
        this.errorMessage = isAmountAboveMaximum ? {heading: amountAboveMaximumHeading, subtitle: amountAboveMaximumSubtitle} : this.errorMessage;

        this.showErrorMessage = isAmountAboveMaximum || isAmountAboveBooking;
      });

      this.changeRate();
    }

    confirm() {
        this.tapNpayService.updatePaymentDetails({amount: this.convertCurrencyToNumber(this.amount)});
        this.tapNpayService.getDevices(this.activeModal);
    }

    changeRate() {
        let rate: number | string | null = this.amountControl.getRawValue();
        rate = Number(rate).toFixed(2);
        this.amountControl.setValue(rate);
    }

    convertCurrencyToNumber(amount: string | number | null) {
        if (typeof amount === 'number') {
            return amount
        } else if(amount === null) {
          return 0;
        }
        else {
            return parseFloat(amount);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
