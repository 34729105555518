import { Component, HostListener, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  @Input() options;

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  close(){
    this.activeModal.dismiss();
  }

  get isIconVisible(): boolean {
    return this.options?.iconVisible ?? true;
  }

  closeModal() {
    if (this.options.closeCallback) {
      this.options.closeCallback();
    } else {
      this.close();
    }
  }

  buttonClick(button){
    this.close();
    button.callback();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKeyPress(event: KeyboardEvent) {
    const button = this.options?.buttons?.find(b => b.triggerOnEnter);
    if (button && event.key === "Enter") {
      event.preventDefault();
      this.buttonClick(button);
    }
  }
}
