import {Component, Input} from '@angular/core';
import {TapNpayPaymentMode, TapNpayWebsocketMessageBT} from "../../tap-n-pay.class";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TapNpayService} from '../../tap-n-pay.service';

@Component({
  selector: 'app-tap-n-pay-modal-approved',
  templateUrl: './tap-n-pay-modal-approved.component.html',
  styleUrls: ['./tap-n-pay-modal-approved.component.scss']
})
export class TapNpayModalApprovedComponent {
  @Input() tapNpayTransactionResponse?: TapNpayWebsocketMessageBT;

  mode: TapNpayPaymentMode = 'payment';
  dateFormat = 'MM/dd/yyyy, hh:mm a';
  currencyCode: string | undefined;

  constructor(
      public activeModal: NgbActiveModal,
      private tapNpayService: TapNpayService
  ) {
    this.mode = this.tapNpayService.paymentDetails.value?.mode;
    this.dateFormat = this.tapNpayService.paymentDetails?.value.dateFormat || this.dateFormat;
    this.currencyCode = tapNpayService.paymentDetails?.value.currencyCode;
  }

}
