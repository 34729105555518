import {BookingRoom} from './BookingRoom';

export class CalendarBooking {
  bookingId: number;
  bbBookingId: number;
  fromDate: string;
  toDate: string;
  days: number;
  rooms: Array<BookingRoom>;
  status: string;
  source: string;
  note: string;
  madebytext: string;
  madebyemail?: string;
  madebyphoneno?: string;
}
