<app-tap-n-pay-modal-container>
        <div class="d-flex justify-content-center my-3">
          <svg class="h-100">
            <use href="assets/images/sprite.svg#info-success"/>
          </svg>
        </div>
        <p class="text-center f-18 p-2 wrap-text fw-semi-bold">{{ mode | titlecase }} successfully processed.</p>
        <hr>
        <div class="d-flex flex-column p-2">
            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Amount:</div>
                <div class="flex-2 fs-14" *ngIf="tapNpayTransactionResponse"> {{ tapNpayTransactionResponse.amount | currency: currencyCode:'symbol-narrow'}} </div>
            </div>
            <div class="d-flex" *ngIf="tapNpayTransactionResponse?.bookingId && tapNpayTransactionResponse?.bookingId !== '0'">
                <div class="fw-semi-bold flex-1 fs-14"> Booking ID:</div>
                <div class="flex-2 fs-14"> {{tapNpayTransactionResponse?.bookingId}} </div>
            </div>
            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Payment ID:</div>
                <div class="flex-2 fs-14"> {{ tapNpayTransactionResponse?.paymentId }}</div>
            </div>
            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Device ID:</div>
                <div class="flex-2 fs-14"> {{ tapNpayTransactionResponse?.deviceId }}</div>
            </div>
            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Date:</div>
                <div class="flex-2 fs-14"> {{ tapNpayTransactionResponse?.transactionDate | date : dateFormat }}</div>
            </div>
        </div>

    <div class="d-flex modal-footer border-top justify-content-between">
        <cui-button class="d-flex flex-1" (onClick)="activeModal.close()" classes="tap-n-pay-btn-success tap-n-pay-btn-action-override" mode="light" label="Finish" name="finish">
        </cui-button>
    </div>
</app-tap-n-pay-modal-container>

