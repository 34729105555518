import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {PageRouteService} from 'src/app/service/page-route.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {ClientsService} from 'src/app/service/clients.service';
import {AccountService} from '../../../service/account.service';
import {Client} from '../../../service/models/Client';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent implements OnInit, OnDestroy {
  @Input() options;

  private initialDataLoad = true;
  pageData;
  returnData;
  clients = new Array<Client>();
  search = {
    firstname: '',
    surname: '',
    company: '',
    email: '',
    phoneno: '',
    bbclientid: '',
  };
  focusSurname = true;
  clientSearchSub: Subscription = new Subscription();

  clientSearchForm = new FormGroup({
    firstname: new FormControl(undefined, Validators.maxLength(20)),
    surname: new FormControl(undefined, Validators.maxLength(40)),
    company: new FormControl(undefined, Validators.maxLength(80)),
    email: new FormControl(undefined, Validators.maxLength(80)),
    phoneno: new FormControl(undefined, Validators.maxLength(40)),
    bbclientid: new FormControl(undefined, [Validators.pattern(/^[0-9]*$/), Validators.maxLength(10)]),
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private pageRouteService: PageRouteService,
    private pageRouteDataService: PageRouteDataService,
    private clientsService: ClientsService,
    private accountService: AccountService
  ) {
  }

  ngOnInit(): void {
    this.pageData = this.pageRouteDataService.getData();
    this.returnData = this.pageRouteDataService.getReturnData();

    this.options = this.options || {};

    this.clientSearchSub = this.clientsService.getClientSearchObs.subscribe(response => {
      if (response !== undefined && response.length > 0 && !this.initialDataLoad) {
        this.clients = response;
        this.clients.forEach((client) => {
          client.fullname = this.accountService.getClientFullName(client);
        });
      } else {
        this.clients = [];
        this.initialDataLoad = false;
      }
    });

    // restore search values and results
    if (this.pageData?.search) {
      this.search = this.pageData.search;
      this.setFormValues();
    }
    if (this.pageData?.clients) {
      this.clients = this.pageData.clients;
    }

    this.clientSearchForm.valueChanges.subscribe(() => {
      this.debounce(()=>{
        this.getFormValues();
        this.searchClients();
      })();
    });

    this.handleReturnData();
  }

  ngOnDestroy() {
    this.clientSearchSub.unsubscribe();
  }

  close(returnData?) {
    if(this.options.roomname !== undefined && returnData !== undefined){
      returnData.roomname = this.options.roomname;
    }
    this.pageRouteService.back(returnData);
  }

  addClient() {
    this.clientsService.addclient = true;
    this.pageRouteService.navigate('clients/search', {
      search: this.search,
      clients: this.clients,
      onClose: 'newClient',
    }, ['/clients/add'], {
      roomname: this.options.roomname,
      clientid: 0,
      search: this.search,
    });
  }

  searchClients() {
    if (!this.searchValid()) {
      this.clients = [];
    } else if (this.clientSearchForm.valid) {
      this.clientsService.searchClients(this.search);
    }
  }

  searchValid() {
    // check if at least one field contains a value
    let validSearch = false;
    Object.keys(this.clientSearchForm.value).forEach((key) => {
      if (this.clientSearchForm.value[key] && this.clientSearchForm.value[key].length > 0) {
        validSearch = true;
      }
    });
    return validSearch;
  }

  selectClient(client) {
    this.close({...client});
  }

  editClient(clientid) {
    this.pageRouteService.navigate('clients/search', {
      search: this.search,
      clients: this.clients,
    }, ['/clients/edit/' + clientid], {
      clientid: clientid,
    });
  }

  clientSelect(client) {
    if (this.options.mode) {
      this.selectClient(client);
    } else {
      this.editClient(client.clientid);
    }
  }

  getFormValues() {
    this.search.firstname = this.clientSearchForm.value.firstname;
    this.search.surname = this.clientSearchForm.value.surname;
    this.search.company = this.clientSearchForm.value.company;
    this.search.email = this.clientSearchForm.value.email;
    this.search.phoneno = this.clientSearchForm.value.phoneno;
    this.search.bbclientid = this.clientSearchForm.value.bbclientid;
  }

  setFormValues() {
    this.clientSearchForm.patchValue({
      firstname: this.search.firstname,
      surname: this.search.surname,
      company: this.search.company,
      email: this.search.email,
      phoneno: this.search.phoneno,
      bbclientid: this.search.bbclientid,
    });
  }

  handleReturnData() {
    if (!this.returnData || !this.pageData?.onClose) {
      return;
    }

    const newClient = (client) => {
      this.selectClient(client);
    };

    // find out from which function we were redirected
    let onClose;
    switch (this.pageData.onClose) {
      case 'newClient':
        onClose = newClient;
        break;
    }

    if (onClose) {
      onClose.bind(this)(this.returnData);
    }
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

}
