<div class="d-flex flex-column align-items-stretch calendarContainer">
  <!-- top bar -->
  <div class="calendarContainer__stickyHeader">
    <div class="d-flex py-2 calendarContainer__stickyHeader left-align">
      <div class="col d-flex calendarContainer__btn_ToolbarContainer justify-content-start gap-md">
        <button class="btn__icon btn__green-icon btn_padding" type="button" (click)="checkinout('in')"
          ngbTooltip="{{ 'calendar' | translate : 'checkIn' }}" tooltipClass="tooltip__container" placement="bottom">
          <img src="assets/images/icons/bell-in.svg">
        </button>
        <button class="btn__icon btn__red-icon btn_padding" type="button" (click)="checkinout('out')"
          ngbTooltip="{{ 'calendar' | translate : 'checkOut' }}" tooltipClass="tooltip__container" placement="bottom">
          <img src="assets/images/icons/bell-out.svg">
        </button>
        <button class="btn__icon btn__stretched-icon btn_padding" type="button" (click)="swapRooms()"
          ngbTooltip="{{ 'calendar' | translate : 'swapRooms' }}" tooltipClass="tooltip__container" placement="bottom">
          <img src="assets/images/icons/swop_rooms.svg">
        </button>
      </div>
      <div class="col-sm-auto d-flex justify-content-center calendarContainer__centerContainer">
        <label class="typography__h2">{{ bbname }} ({{ bbid }})</label>
        <div class="d-flex calendarContainer__btn_property">
          <div (click)="manageProperties()" *ngIf="showManageProperties" class="btn__secondary btn__stretched-icon"
            title="{{'calendar'| translate : 'myProperties'}}">
            <img src="assets/images/icons/myprop_icon.svg">
          </div>
        </div>
      </div>
      <div class="col d-flex calendarContainer__btn_ToolbarContainer justify-content-end">
        <button class="btn__regular btn btn__primary addBookingBtn" (click)="addBooking()">
          {{ 'calendar' | translate : 'bookingAdd' }}
        </button>
        <button class="btn__regular btn btn__secondary quoteBtn" (click)="showQuote()">
          {{ 'calendar' | translate : 'quote' }}
        </button>
      </div>
    </div>
    <div class="d-flex align-items-center py-2 calendarContainer__toolbar">
      <div class="col-md-2 d-flex align-items-center">
        <button class="calendarContainer__toolbar__navBtn btn" (click)="setCalendar(calendar.today)">
          {{ 'calendar' | translate : 'today' }}
        </button>
      </div>
      <div class="col-md-8 d-flex justify-content-center">
        <button class="calendarContainer__toolbar__navBtn_Side mx-0" (click)="goBack()" [disabled]="waitCallback">
          <img src="assets/images/icons/left_single_arrow.svg">
        </button>
        <button class="calendarContainer__toolbar__navBtn_Side" (click)="goBackMore()" [disabled]="waitCallback">
          <img src="assets/images/icons/left_double_arrow.svg">
        </button>
        <div class="d-flex align-items-center col-md-3">
          <div class="input-group">
            <input type="text" class="form-control typography__p" #start="bsDatepicker" bsDatepicker readonly
              appDatepicker [bsConfig]="{ dateInputFormat:'MMMM YYYY', minMode:'day', startView:'month' }"
              [(ngModel)]="datepickerDate" />
            <div class="input-group-prepend">
              <button (click)="start.toggle()" class="input-group-text">
                <img src="assets/images/icons/key_arrow_down.svg">
              </button>
            </div>
          </div>
        </div>

        <button class="calendarContainer__toolbar__navBtn_Side" (click)="goForwardMore()" [disabled]="waitCallback">
          <img src="assets/images/icons/right_double_arrow.svg">
        </button>
        <button class="calendarContainer__toolbar__navBtn_Side mx-0" (click)="goForward()" [disabled]="waitCallback">
          <img src="assets/images/icons/right_single_arrow.svg">
        </button>
      </div>
    </div>
    <div class="calendar--head"
      [style]="{'grid-template-columns': 'auto repeat(' + (dates.length) + ', ' + gridSize.dayWidth + 'px)', 'grid-template-rows': 'auto'}">

      <!--the roomtype toggle button-->
      <div class="calendar--head__room" style="grid-column: 1; grid-row:1;">
        <label class="switch" [ngbTooltip]="getRoomTypeTooltip()" tooltipClass="tooltip__container">
          <input type="checkbox" (change)="toggleCheckBox()" [disabled]="toggleRoomTypeDataLoading"
            [(ngModel)]="roomTypeMode">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="day_header calendar--head__date" [style]="{'grid-column': dayIndex + 2, 'grid-row:' : 1}"
        *ngFor="let day of dates; let dayIndex = index;  trackBy: trackByDates"
        (mousedown)="selectAllDayMouseDown($event, dayIndex)" (mouseup)="selectAllDayMouseUp($event, dayIndex)"
        (mouseover)="selectAllDayMouseOver($event, dayIndex)" [ngClass]="{'event' : day.events.length > 0}"
        [class]="day.class" (click)="onDayClick(day)" (dblclick)="onDayDoubleClick(day)">
        <div class=" w-100 day_header__event_count event__badge" *ngIf="day.events.length > 0"
          (click)="openEventListModal(day)">
          <sup class="event__badge--icon">
            <span>{{day.events.length}}</span>
          </sup>
        </div>
        <div class="d-flex flex-column align-items-center day_item">
          <div class="d-flex justify-content-between">
            {{getDate(day.date)}}
          </div>
          <div>{{getDay(day.date)}}</div>
          <div>{{getMonth(day.date)}}</div>
        </div>
      </div>
    </div>
  </div>

  <!-- calendar grid -->
  <div class="calendar">

    <div class="calendar--table" (mouseleave)="cancelSelection()">


      <!--ROOM MODE VIEW-->
      <div class="calendar--body"
        [style]="{'grid-template-columns': 'auto repeat(' + (dates.length) + ', ' + gridSize.dayWidth + 'px)', 'grid-template-rows': 'auto'}"
        *ngIf="isRoomMode">
        <ng-container *ngFor="let room of calendarData.rooms; let roomIndex = index;">
          <div class="calendar--body__room room_cell d-flex align-items-center"
            [style]="{'grid-column': 1, 'grid-row':(roomIndex + 1)}" [ngbTooltip]="room.roomType"
            tooltipClass="tooltip__container">
            <span>{{room.roomName}}</span>
          </div>

          <div class="calendar--body__date" *ngFor="let day of dates; let dayIndex = index"
            [style]="getStyle(roomIndex, dayIndex, day.date, room)"
            (mousedown)="selectCellMouseDown(getCalendarSelection(), $event, dayIndex, roomIndex, day.date, room)"
            (mouseup)="selectCellMouseUp(getCalendarSelection(), $event, dayIndex, roomIndex)"
            (mouseover)="mouseOverCell(getCalendarSelection(), dayIndex, roomIndex)"
            [ngClass]="{'selected':isCellSelected(getCalendarSelection(), dayIndex, roomIndex), 'no-hover': !!getBooking(day.date, room)}">

            <div class="calendar--body__booking" *ngIf="getBooking(day.date, room) as booking"
              [style]="{'z-index':(dayIndex + 1), 'width':getWidth(booking)}" #p="ngbPopover" [ngbPopover]="bookingInfo"
              [autoClose]="'outside'" popoverClass="guest-data-popover" placement="bottom" container="body" [popperOptions]="popperOptions"
              (mouseenter)="openPopOver(p)" (mouseleave)="clearPopoverTimeout()" [disablePopover]="isMobile() || (modalIsOpen$ | async)"
              (click)="viewDetails($event, booking[0], room.bbRoomId,bottomSheetContent)" triggers="manual">
              
              <ng-template #bookingInfo >
                <app-guest-data-card (mouseover)="keepPopoverOpen()" (mouseleave)="clearPopoverTimeout()" [booking]="booking[0]" [room]="room" [popover]="p"></app-guest-data-card>
              </ng-template>

              <ng-template #bottomSheetContent let-bottomSheet>
                <div class="offcanvas-header border-bottom border-bottom-2 d-flex flex-column">
                  <div class="d-flex align-items-center justify-content-center mt-2 mb-4">
                    <svg class="ic w-100">
                      <use href="assets/images/sprite.svg#drawer-icon" />
                    </svg>
                  </div>
                  <div class="d-flex align-items-center justify-content-between w-100 px-3">
                    <h4 class="offcanvas-title fs-1 fw-bold" id="offcanvas-basic-title">{{getGuestDisplayName(booking[0],
                      room)}}
                    </h4>
                    <button type="button" class="btn-close fs-3" 
                      (click)="bottomSheet.dismiss('Cross click')"></button>
                  </div>
                </div>
                <div class="offcanvas-body border-bottom border-bottom-2" ngbAutofocus>
                  <app-guest-data-card [booking]="booking[0]" [room]="room" [popover]="p"></app-guest-data-card>
                </div>
                <div class="offcanvas-bottom my-4 pb-3">
                  <div class="d-flex justify-content-center gap-2">
                    <cui-button type="outlined" class="w-min-45p" classes="fs-2 my-2 btn__outlined"
                      (click)="bottomSheet.dismiss('Close click')"> Close
                    </cui-button>
                    <cui-button type="filled" class="w-min-45p" classes="fs-2 my-2"
                      (click)="bottomSheet.dismiss('Close click'); openBooking($event, booking[0], room.bbRoomId)"> View
                      More </cui-button>
                  </div>
                </div>
              </ng-template>

              <div class="booking-start" [style]="getBookingStatusStyle(booking[0], room.bbRoomId)">
                <span class="guest-count">{{getBookingAdultsCount(booking[0], room)}}
                  <sup *ngIf="!!getBookingChildCount(booking[0], room)">
                    {{getBookingChildCount(booking[0], room)}}
                  </sup>
                </span>
              </div>
              <div class="booking-details" [style]="getBookingBodyStyle(booking[0], room.bbRoomId)">
                <span class="guest-name" style="opacity: 1;color: black;">{{getGuestName(booking[0], room)}}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <!--ROOM TYPE / AVAILABILITY MODE-->
      <div class="calendar--body" *ngIf="!isRoomMode">
        <div *ngFor="let roomType of getRoomTypes; let roomTypeIndex = index;" style="display:grid"
          [style]="{'grid-template-columns': 'auto repeat(' + (dates.length) + ', ' + gridSize.dayWidth + 'px)', 'grid-template-rows': 'auto'}"
          (mouseleave)="cancelSelection(roomTypeIndex)">
          <div class="calendar--body__room room_cell d-flex align-items-center" [ngbTooltip]="roomType.rtName"
            tooltipClass="tooltip__container" [disableTooltip]="modalIsOpen$ | async"
            (click)="selectRoomType(roomType.bbRtId)">
            <!--             (click)="selectRoom($event, roomIndex)">-->
            <img class="mx-2" src="../../../assets/images/icons/hotel_bed.svg" />
            <span class="rtname">{{roomType.rtName}}</span>
            <span class="rtname-icon" *ngIf="calendarService.getRoomTypeOpenStatusData.get(roomType.bbRtId+'')">
              <img src="../../../assets/images/icons/dropdown_btn_up.svg" />
            </span>
            <span class="rtname-icon" *ngIf="!calendarService.getRoomTypeOpenStatusData.get(roomType.bbRtId+'')">
              <img src="../../../assets/images/icons/dropdown_btn_down.svg" />
            </span>
          </div>
          <div *ngFor="let day of dates; let dayIndex = index">
            <div class="calendar--body__date" *ngIf="getRoomTypeCLoseOut(roomType.bbRtId, day.date)"
              style="margin: auto; background: rgb(243, 207, 209)" [ngbTooltip]="getCloseOutTooltip()"
              tooltipClass="tooltip__container" triggers="click hover">
              <div class="d-flex flex-column align-items-center" style="margin: auto; background: rgb(243, 207, 209)">
                {{getRoomTypeAvailability(roomType, day.date)}}
              </div>
            </div>
            <div class="calendar--body__date" *ngIf="!getRoomTypeCLoseOut(roomType.bbRtId, day.date)"
              style="margin: auto;">
              <div class="d-flex flex-column align-items-center" style="margin: auto;">
                {{getRoomTypeAvailability(roomType, day.date)}}
              </div>
            </div>
          </div>

          <ng-container *ngIf="calendarService.getRoomTypeOpenStatusData.get(roomType.bbRtId+'')">
            <ng-container
              *ngFor="let room of calendarData.roomTypes.get(roomType.bbRtId.toString()).rooms; index as roomIndex; trackBy: trackIndex">

              <div class="calendar--body__room room_cell d-flex align-items-center"
                [style]="{'grid-column': 0, 'grid-row':2 + roomIndex}">
                <!--             (click)="selectRoom($event, roomIndex)">-->
                <!--                <img class="mx-2" src="assets/images/icons/bed.svg"/>-->
                {{room.roomName}}
              </div>

              <div class="calendar--body__date" *ngFor="let day of dates; let dayIndex = index"
                [style]="getStyle(roomIndex + 1, dayIndex, day.date, room)"
                (mousedown)="selectCellMouseDown(getCalendarSelection(roomTypeIndex), $event, dayIndex, roomIndex, day.date, room)"
                (mouseup)="selectCellMouseUp(getCalendarSelection(roomTypeIndex), $event, dayIndex, roomIndex, roomType.bbRtId)"
                (mouseover)="mouseOverCell(getCalendarSelection(roomTypeIndex), dayIndex, roomIndex, roomType.bbRtId)"
                [ngClass]="{'selected':isCellSelected(getCalendarSelection(roomTypeIndex), dayIndex, roomIndex), 'no-hover': !!getBooking(day.date, room)}">

                <div class="calendar--body__booking" *ngIf="getBooking(day.date, room) as booking"
                  [style]="{'z-index':(dayIndex + 1), 'width':getWidth(booking)}" #p="ngbPopover" [ngbPopover]="bookingInfo"
                  [autoClose]="'outside'" popoverClass="guest-data-popover" placement="bottom" container="body" [popperOptions]="popperOptions"
                  (mouseenter)="openPopOver(p)" (mouseleave)="clearPopoverTimeout()" [disablePopover]="isMobile() || (modalIsOpen$ | async)"
                  (click)="viewDetails($event, booking[0], room.bbRoomId,bottomSheetContent)" triggers="manual">
                
                  <ng-template #bookingInfo >
                    <app-guest-data-card (mouseover)="keepPopoverOpen()" (mouseleave)="clearPopoverTimeout()" [booking]="booking[0]" [room]="room" [popover]="p"></app-guest-data-card>
                  </ng-template>
                  
                  <ng-template #bottomSheetContent let-bottomSheet>
                    <div class="offcanvas-header border-bottom border-bottom-2 d-flex flex-column">
                      <div class="d-flex align-items-center justify-content-center mt-2 mb-4">
                        <svg class="ic w-100">
                          <use href="assets/images/sprite.svg#drawer-icon" />
                        </svg>
                      </div>
                      <div class="d-flex align-items-center justify-content-between w-100 px-3">
                        <h4 class="offcanvas-title fs-1 fw-bold" id="offcanvas-basic-title">{{getGuestDisplayName(booking[0],
                          room)}}
                        </h4>
                        <button type="button" class="btn-close fs-3" 
                          (click)="bottomSheet.dismiss('Cross click')"></button>
                      </div>
                    </div>
                    <div class="offcanvas-body border-bottom border-bottom-2" ngbAutofocus>
                      <app-guest-data-card [booking]="booking[0]" [room]="room" [popover]="p"></app-guest-data-card>
                    </div>
                    <div class="offcanvas-bottom my-4 pb-3">
                      <div class="d-flex justify-content-center gap-2">
                        <cui-button type="outlined" class="w-min-45p" classes="fs-2 my-2 btn__outlined"
                          (click)="bottomSheet.dismiss('Close click')"> Close
                        </cui-button>
                        <cui-button type="filled" class="w-min-45p" classes="fs-2 my-2"
                          (click)="bottomSheet.dismiss('Close click'); openBooking($event, booking[0], room.bbRoomId)"> View
                          More </cui-button>
                      </div>
                    </div>
                  </ng-template>

                  <div class="booking-start" [style]="getBookingStatusStyle(booking[0], room.bbRoomId)">
                    <span class="guest-count">{{getBookingAdultsCount(booking[0], room)}}
                      <sup *ngIf="!!getBookingChildCount(booking[0], room)">
                        {{getBookingChildCount(booking[0], room)}}
                      </sup>
                    </span>
                  </div>
                  <div class="booking-details" [style]="getBookingBodyStyle(booking[0], room.bbRoomId)">
                    <span class="guest-name">{{getGuestName(booking[0], room)}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
</div>