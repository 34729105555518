 <div class="row mb-2 py-3">

     <div class="d-flex flex-column flex-sm-row gap-1">

         <div class="flex-sm-1">
             <label class="fw-bold mb-3">
                 {{"accountSummary" | translate: "itemsLabel"}}
             </label>
         </div>

         <div class="d-flex justify-content-start justify-content-sm-end gap-2">
             <app-tap-n-pay
                 *ngIf="(isTapNpayEnabled$ | async) && !!getBookingIdFromItems(items)"
                 [classes]="'max-h-sm-48'"
                 [details]="{
                          bbId: account.bbid,
                          amount: 0,
                          bookingId: getBookingIdFromItems(items),
                          mode: 'payment',
                          refundPaymentId: '',
                          currencyCode: getCurrency()
                        }"
                 [bookingDetails]="{
                          amountDue: 0,
                          accommodationTotal: account.accomtotal,
                          extraTotal: account.extratotal,
                          paymentsTotal: account.paymenttotal,
                          clientFullName: account?.details?.clientname,
                          fromDate: '',
                          toDate: ''
                        }"
             ></app-tap-n-pay>
             <cui-button (click)="addPayment.emit()" [classes]="'max-h-sm-48'">
                 {{"accountSummary" | translate: "addPayment"}}
             </cui-button>

             <cui-button (click)="generateCcLink.emit()" [classes]="'max-h-sm-48'">
                 {{"accountSummary" | translate: "creditLink"}}
             </cui-button>
         </div>
     </div>

  </div>
  <div *ngIf="!isMobile()" class="table-scrollable-md">
    <table class="table table-striped table-hover table-responsive border-top">
      <thead class="border-bottom">
        <tr>
          <th scope="col" class="fw-bold">{{"accountSummary" | translate: "date"}}</th>
          <th scope="col" class="fw-bold">{{"accountSummary" | translate: "description"}}</th>
          <th scope="col" class="fw-bold">{{"accountSummary" | translate: "debit"}}</th>
          <th scope="col" class="fw-bold text-right">{{"accountSummary" | translate: "credit"}}</th>
        </tr>
      </thead>
      <tbody *ngIf="items">
        <tr *ngFor="let item of items; let i=index;" (click)="viewItem.emit(item)"
          ngbTooltip="{{getToolTipForAccountItem(item)}}" tooltipClass="tooltip__container">
          <td class="typography__p">{{item.date | date:'dd/MM/yyyy'}}</td>
          <td class="typography__p">{{item.description}}</td>
          <td class="typography__p">{{getAmount(item.debit) | currency}}</td>
          <td class="typography__p currencyTextAlignRight">{{getAmount(item.credit) | currency}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="isMobile()">
    <div class="d-flex flex-column p-2 mt-2 item border" *ngFor="let item of items; let i=index;"
      ngbTooltip="{{getToolTipForAccountItem(item)}}" tooltipClass="tooltip__container" (click)="viewItem.emit(item)">
      <div>
        <span class="fw-bold">{{"accountSummary" | translate: "date"}}: </span><span>{{item.date | date:'dd/MM/yyyy'}}</span>
      </div>
      <div>
        <span class="fw-bold">{{"accountSummary" | translate: "description"}}: </span><span>{{item.description}}</span>
      </div>
      <div>
        <span class="fw-bold">{{"accountSummary" | translate: "debit"}}: </span><span>{{getAmount(item.debit) |
          currency}}</span>
      </div>
      <div>
        <span class="fw-bold">{{"accountSummary" | translate: "credit"}}: </span><span>{{getAmount(item.credit) |
          currency}}</span>
      </div>
    </div>
  </div>
