import {Component, HostBinding, HostListener, OnInit, TemplateRef} from '@angular/core';

import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {CalendarService} from 'src/app/service/calendar.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {ModalContainerService} from 'src/app/service/modal-container.service';
import {Router, ActivatedRoute} from '@angular/router';
import {CalendarSelection, CalendarSelectionCell} from '../../service/models/CalendarSelectionCell';
import {Calendar} from '../../service/models/Calendar';
import * as moment from 'moment/moment';
import {CalendarBooking} from '../../service/models/CalendarBooking';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {CalendarRoom} from '../../service/models/CalendarRoom';
import {CalendarRoomType} from '../../service/models/CalendarRoomType';
import {BookingRoom} from '../../service/models/BookingRoom';
import {WebsocketService} from 'src/app/service/websocket.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {TranslateService} from 'src/app/service/translate.service';
import {BookingNotificationService} from 'src/app/service/booking-notification.service';
import {CredentialService} from "../../service/credential.service";
import {DoubleBookingsService} from 'src/app/service/double-bookings.service';
import {NetworkService} from "../../service/network.service";
import {ModalPopupService} from "../../service/modal-popup.service";
import {Observable, Subject, takeUntil, timer} from 'rxjs';
import {EventsService} from 'src/app/service/events.service';
import {formatDate} from "@angular/common";
import {WebsocketProvisionalData} from '../../service/models/General';
import {DisplayManagerService} from 'src/app/service/display-manager.service';
import {NgbOffcanvas, NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import {Meta} from "@angular/platform-browser";
import {Options} from '@popperjs/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  private destroy$ = new Subject<void>();
  private holdCancelled$ = new Subject<void>();

  waitCallback: boolean = false;
  toggleRoomTypeDataLoading: boolean = false;
  initialLoad = true;
  refreshSelection = false;
  pageData;
  returnData;

  bbname;
  bbid;
  events = [];
  bookings = [];

  rooms = [];
  roomTypeMode = false;

  dates = [];

  selectedRooms = {};
  roomMap = {};
  bookingDates: {[key: string]: any} = {};
  calPadding = 1; // TODO get from nb.settings["calendar_padding"].value;

  calendarRoomSelection: CalendarSelection;
  calendarRoomTypeSelection: Array<CalendarSelection>;
  calendarData: Calendar = new Calendar();
  bookingData: {};
  availabilityBookingData: {};
  closeouts = [];
  closeOutType;
  closeOutToolTipMap = {};
  trackIndex = i => {return i};
  currentPopover: NgbPopover;
  popoverTimer;

  modalIsOpen$: Observable<boolean>;
  /**
   * Information about the size of the elements displayed on the screen, used to
   * calculate how many days should be shown on the calendar to avoid horizontal
   * scrolling.
   */
  gridSize = {
    // TODO double-check if any extra padding is needed for the calculation
    roomWidth: 180, // width of the room column in pixels
    dayWidth: 60, // width of a day cell in pixels
  };

  /**
   * The getter and setter allow choosing when setting datepickerDate will trigger
   * an update on the calendar. If the new value matches the old one, or if it matches
   * the second date shown, nothing will trigger.
   */
  _datepickerDate = new Date();
  set datepickerDate(value: Date) {
    if (
      this._datepickerDate.toISOString().slice(0, 10) === value.toISOString().slice(0, 10) ||
      value.toISOString().slice(0, 10) === this.dates[this.calPadding].date.toISOString().slice(0, 10)
    ) {
      return;
    }
    this._datepickerDate = value;
    this.setCalendar(value);
  }

  get datepickerDate() {
    return this._datepickerDate;
  }

  /**
   * Information on the dates displayed in the calendar.
   */
  calendar = {
    today: new Date(),
    start: new Date(),
    end: new Date(),
  };

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  @HostBinding('class.d-none') isHidden = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public calendarService: CalendarService,
    private pageRouteService: PageRouteService,
    private websocketService: WebsocketService,
    protected translateService: TranslateService,
    private credentialService: CredentialService,
    private datelibraryService: DatelibraryService,
    private confirmationService: ConfirmationService,
    private pageRouteDataService: PageRouteDataService,
    private doubleBookingsService: DoubleBookingsService,
    private modalContainerService: ModalContainerService,
    private bookingNotificationService: BookingNotificationService,
    private networkService: NetworkService,
    private modalPopupService: ModalPopupService,
    private eventsService: EventsService,
    private displayManagerService: DisplayManagerService,
    private ngbOffCanvas: NgbOffcanvas,
    private metaService: Meta
  ) {
    // update calendar data everytime a modal closes
    this.toggleRoomTypeDataLoading = false;
    this.modalIsOpen$ = this.modalContainerService.modalIsOpenChanged$;

    this.modalContainerService.modalObservable.subscribe((modalInstance) => {
      modalInstance?.result.then(() => {
        this.refreshGrid(this.refreshSelection);
        this.refreshSelection = true;
      });
    });

    this.eventsService.notifySubject.subscribe(() => this.refreshGrid(false));

    // handle return data everytime the last modal closes
    this.pageRouteService.closeObservable.subscribe(() => {
      this.pageData = this.pageRouteDataService.getData();
      this.returnData = this.pageRouteDataService.getReturnData();
      this.handleReturnData();
      this.isHidden = false;
    });

    this.displayManagerService.hideCalendar$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isHidden) => {
        this.isHidden = isHidden;
      });

    // initial values so the calendar doesn't look empty before first load
    this.calendar.end = this.datelibraryService.addDays(new Date(this.calendar.start), this.calendarService.getDayCount);
    this.dates = Array.from({length: this.calendarService.getDayCount}, (_, i) => {
      const date = this.datelibraryService.addDays(new Date(this.calendar.start), i);
      return {
        date: date,
        class: '',
        events: [],
      };
    });
    this.rooms = [{
      roomname: 'Room A',
    }, {
      roomname: 'Room B',
    }, {
      roomname: 'Room C',
    }];
  }



  /**
   * Helper data on the double click on the date headers.
   */
  doubleClick = {
    // how many ms between clicks to consider 2 clicks as a double click
    delay: 250,

    // flag to keep track of the first click
    singleClick: false,

    // timer to check if another click was already triggered
    timer: <any>undefined,
  };

  ngOnInit(): void {
    // get how many days should be displayed on the screen
    const screenWidth = window.innerWidth;
    this.refreshGrid(true);

    // set the calendar to start today
    this.setCalendar(this.calendar.today);

    // start polling websocket data
    this.websocketService.messageObservable.subscribe(this.processWebsocketData.bind(this));

    // watch for any component updates to calendar's date
    this.calendarService.dateObservable.subscribe((date) => {
      this.setCalendar(date);
    });

    const currentPath = window.location.pathname;
    if (currentPath.indexOf('calendar') >= 0) {
      this.checkDoubleBookings();
    }
  }

  openBottomSheet(templateRef: TemplateRef<any>) {
    this.metaService.updateTag({name: 'viewport', content: 'width=device-width, initial-scale=0.65'});
    const offCanvas = this.ngbOffCanvas.open(templateRef, {position: 'bottom'});
    offCanvas.closed.subscribe(() => {
      this.displayManagerService.updateViewport();
    })
  }

  checkDoubleBookings() {
    this.doubleBookingsService.getData().subscribe((response: any) => {
      let doubleBookingExists = response.data.doublebookings.length !== 0;

      if (doubleBookingExists) {
        this.confirmationService.show({
          title: 'confirmation',
          text: this.translateService.translate('doubleBookings', 'doubleBookingsFound'),
          buttons: [{
            text: 'no',
            class: 'btn__warning',
            wrappingClass: 'flex-1',
            callback: () => {
            }
          }, {
            text: 'yes',
            class: 'btn__green',
            wrappingClass: 'flex-1',
            callback: () => {
              this.pageRouteService.navigate('/calendar', {}, ['/tools/double-booking']);
            }
          }]
        });
      }
    });
  }

  get showManageProperties() {
    return this.credentialService.getCurrentUser.value.allowMultiProperty;
  }

  get isRoomMode() {
    return !this.roomTypeMode;
  }

  refreshGrid(refreshSelection: boolean) {
    this.getData(refreshSelection);
  }

  setDaysToDisplay() {
    const isToday = (date) => date.toISOString().slice(0, 10) === this.calendar.today.toISOString().slice(0, 10);
    const isWeekend = (date) => [0, 6].includes(date.getDay());

    // build the dates' array according to `this.calendar` and set the classes used on the html
    this.dates = Array.from({length: this.calendarService.getDayCount}, (_, i) => {
      const date = this.datelibraryService.addDays(new Date(this.calendar.start), i);
      return {
        date: date,
        class: [
          isToday(date) ? 'today' : '',
          isWeekend(date) ? 'weekend' : ''
        ].join(' '),
        events: this.events.filter((event) => {
          const dateString = date.toISOString().slice(0, 10);
          return event.fromdate <= dateString && dateString <= event.todate;
        }),
      };
    });


    // update the date shown on the datepicker
    this.datepickerDate = this.dates[this.calPadding].date;
    this._datepickerDate = this.dates[this.calPadding].date;
  }

  getData(refreshSelection: boolean) {
    this.calendarService.getCalendarData(this.calendar.start, this.getCalendarEndDate()).then((response: any) => {
      this.bbname = response.data.bb.bbname;
      this.bbid = response.data.bb.bbid;

      this.events = response.data.events;
      this.bookings = response.data.bookings;

      this.roomTypeMode = response.data.roomtypemode;
      this.rooms = response.data.rooms;

      this.roomMap = this.buildMap(response.data.rooms, 'bbroomid');

      this.extractData(response);
      this.bookingData = this.refreshBookingData();

      if (this.initialLoad || refreshSelection) {

        this.calendarRoomSelection = this.populateCalendarSelectionMatrix(this.calendarData.rooms);
        this.calendarRoomTypeSelection = new Array<CalendarSelection>();
        for (let roomTypeId of Array.from(this.calendarData.roomTypes.keys())) {
          this.calendarRoomTypeSelection.push(this.populateCalendarSelectionMatrix(this.calendarData.roomTypes.get(roomTypeId + '').rooms));
        }

        this.closeouts = response.data.closeouts;

        this.initialLoad = false;
      }

      this.setDaysToDisplay();
    }).finally(() => {
      this.waitCallback = false;
    });
  }

  getCalendarEndDate() {
    return this.datelibraryService.addDays(new Date(this.calendar.start), this.calendarService.getDayCount);
  }

  getSelectedRooms(selectedRoomType?: CalendarRoomType, selectedRoomTypeIndex?) {
    // TODO double-check if these are the correct selected rooms
    return this.rooms.filter((room) => {
      if (this.roomTypeMode && !!selectedRoomType) {
        let selectedRoomTypeIndexes = this.calendarRoomTypeSelection[selectedRoomTypeIndex].selectedRooms;
        let selectedRoomTypeRooms = selectedRoomType.rooms;

        let temp = selectedRoomTypeRooms.filter((calendarRoom) => {
          return selectedRoomTypeIndexes.includes(calendarRoom.bbRoomId);
        })

        return temp.findIndex(roomData => roomData.bbRoomId === room.bbroomid) > -1;
      } else {
        return this.calendarRoomSelection.selectedRooms.includes(room.bbroomid);
      }
    });
  }

  buildMap(values, key) {
    const map = {};
    values.forEach((value) => {
      map[value[key]] = value;
    });
    return map;
  }

  addBooking() {
    this.networkService.checkNetworkStatus();
    let fromdate, todate;
    let roomsSelected = this.getSelectedRooms();

    if (this.roomTypeMode) {
      const selection = this.getFromCalendarRoomTypeSelection();
      fromdate = selection.fromdate;
      todate = selection.todate;
      roomsSelected = selection.roomsSelected;
    } else if (this.calendarRoomSelection.startXIndex > -1 && this.calendarRoomSelection.endXIndex > -1) {
      const selection = this.getFromCalendarRoomSelection();
      fromdate = selection.fromdate;
      todate = selection.todate;
    } else {
      fromdate = new Date(this.calendar.start);
      todate = this.datelibraryService.addDaysTwo(fromdate, 1);
      roomsSelected = this.getSelectedRooms();
    }

    this.bookingDates = {
      fromdate,
      todate
    };

    const {
      from: bookingFromDate,
      to: bookingToDate
    } = this.getCalendarDateRangeOrDefault(roomsSelected, fromdate, todate);

    this.pageRouteService.navigate('/calendar', {
      onClose: 'openBooking',
    }, ['/add-booking'], {
      fromdate: bookingFromDate,
      todate: bookingToDate,
      rooms: roomsSelected,
    });
  }

  setCalendar(date) {
    if (date === undefined) {
      return;
    }
    const currentPosition = new Date(this.calendar.start);
    const dateToGoTo = new Date(date);
    let differenceInTime = dateToGoTo.getTime() - currentPosition.getTime();
    let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    this.calendar.start = this.datelibraryService.addDays(this.calendar.start, differenceInDays - 1);
    this.calendar.end = this.datelibraryService.addDays(new Date(this.calendar.start), this.calendarService.getDayCount);

    this.getData(true);
  }

  moveCalendar(howManyDays) {
    this.calendar.start = this.datelibraryService.addDays(new Date(this.calendar.start), howManyDays);
    this.calendar.end = this.datelibraryService.addDays(new Date(this.calendar.start), this.calendarService.getDayCount);

    this.getData(true);
  }

  goBack() {
    this.waitCallback = true;
    this.moveCalendar(-7);
  }

  goForward() {
    this.waitCallback = true;
    this.moveCalendar(7);
  }

  goBackMore() {
    this.waitCallback = true;
    this.moveCalendar(-this.calendarService.getDayCount);
  }

  goForwardMore() {
    this.waitCallback = true;
    this.moveCalendar(this.calendarService.getDayCount);
  }

  trackByDates(index, date): number {
    return date.date.getTime();
  }

  onDayClick(day) {
    // warning: this is also triggered when user clicks on the comment icon
    this.doubleClick.singleClick = true;
    setTimeout(() => {
      if (this.doubleClick.singleClick) {
        this.handleDayClick(day);
      }
    }, this.doubleClick.delay);
  }

  onDayDoubleClick(day) {
    this.doubleClick.singleClick = false;
    this.handleDayDoubleClick(day);
  }

  handleDayClick(day) {
    // TODO
  }

  handleDayDoubleClick(day) {
    this.networkService.checkNetworkStatus();
    this.pageRouteService.navigate('/calendar', {}, ['/events/add'], {
      date: day.date,
      eventid: 0,
    });
  }

  openEventListModal(day) {
    this.networkService.checkNetworkStatus();
    if (day.events.length === 0) {
      return;
    }
    this.pageRouteService.navigate('/calendar', {}, ['/events'], {
      date: day.date,
      events: day.events,
    });
  }

  toggleCheckBox() {

    if (this.toggleRoomTypeDataLoading) {
      return;
    }

    this.toggleRoomTypeDataLoading = true;

    this.calendarService.updatePreferencesMode(this.roomTypeMode).subscribe(() => {
      this.refreshGrid(true);
      this.toggleRoomTypeDataLoading = false;
    });
  }

  isMobile() {
    return this.displayManagerService.isMobile();
  }

  checkinout(action: 'in' | 'out') {
    if (this.networkService.getStatusOnline === 0) {
      this.networkService.checkNetworkStatus();
      return;
    }
    this.pageRouteService.navigate('/calendar', {}, ['/check-in-out'], {action});
  }

  swapRooms() {
    if (this.networkService.getStatusOnline === 0) {
      this.networkService.checkNetworkStatus();
      return;
    }
    this.pageRouteService.navigate('/calendar', {}, ['/swap-rooms'], {
      date: this.dates[this.calPadding].date,
    });
  }

  showQuote() {
    if (this.networkService.getStatusOnline === 0) {
      this.networkService.checkNetworkStatus();
      return;
    }

    let fromdate, todate;
    let roomsSelected = this.getSelectedRooms();

    if (this.roomTypeMode) {
      const selection = this.getFromCalendarRoomTypeSelection();
      fromdate = selection.fromdate;
      todate = selection.todate;
      roomsSelected = selection.roomsSelected;
    } else if (this.calendarRoomSelection.startXIndex > -1 && this.calendarRoomSelection.endXIndex > -1) {
      const selection = this.getFromCalendarRoomSelection();
      fromdate = selection.fromdate;
      todate = selection.todate;
    } else {
      fromdate = new Date(this.calendar.start);
      todate = this.datelibraryService.addDaysTwo(fromdate, 1);
    }

    const {from: quoteFromDate, to: quoteToDate} = this.getCalendarDateRangeOrDefault(roomsSelected, fromdate, todate);

    this.pageRouteService.navigate('/calendar', {}, ['/quote'], {
      fromdate: quoteFromDate,
      todate: quoteToDate,
      rooms: roomsSelected,
    });
  }

  /*should be changed to generic service later todo*/
  getDay(day) {
    return moment(day).format('D');
  }

  getDate(day) {
    return moment(day).format('ddd');
  }

  getMonth(day) {
    return moment(day).format('MMM');
  }

  getStyle(roomIndex: number, dayIndex: number, day, room) {
    const booking = this.getBooking(day, room);

    if (!!booking && booking.length > 1) {
      // todo double booking
    } else if (!!booking) {

      let isBookingStartDateBeforeDisplayDate = moment(booking[0].fromDate).diff(this.calendar.start.toISOString().substring(0, 10), 'days') < 0;

      let displayNights = isBookingStartDateBeforeDisplayDate ?
        this.datelibraryService.getDifferenceBetweenDates(this.calendar.start.toISOString().substring(0, 10), booking[0].toDate) :
        booking[0].days;

      let toIndex = dayIndex + 2 + displayNights;
      toIndex = toIndex > this.calendarService.getDayCount + 2 ? this.calendarService.getDayCount + 2 : toIndex;

      return {
        'grid-column': (dayIndex + 2) + ' / ' + toIndex,
        'grid-row': roomIndex + 1
      };
    }

    return {'grid-column': (dayIndex + 2), 'grid-row': (roomIndex + 1)};
  }

  getBooking(day, room): Array<CalendarBooking> {
    const dayInString = moment(day).format('YYYY-MM-DD');

    return !!this.bookingData[dayInString] && !!this.bookingData[dayInString][room.bbRoomId + ''] ?
      this.bookingData[dayInString][room.bbRoomId + ''] : undefined;
  }

  getBookingForAvailability(day, room) {
    const dayInString = moment(day).format('YYYY-MM-DD');

    let bookingKey = Object.keys(this.availabilityBookingData).filter(key => {

      if (key.startsWith(dayInString)) {
        return true;
      }

      let keys = key.split('/');

      let bookingStartDate = new Date(keys[0]);
      let bookingEndDate = new Date(keys[1]);
      let dayView = new Date(day);

      return dayView > bookingStartDate && dayView <= bookingEndDate;
    });

    let key = bookingKey[0] || '';
    if (!!bookingKey && bookingKey.length > 1) {
      for (let bookingKeyData of bookingKey) {
        let rooms = this.availabilityBookingData[bookingKeyData];
        if (rooms[room.bbRoomId + '']) {
          key = bookingKeyData;
        }
      }
    }

    return !!key && !!this.availabilityBookingData[key] && !!this.availabilityBookingData[key][room.bbRoomId + ''] ?
      this.availabilityBookingData[key][room.bbRoomId + ''] : undefined;
  }

  mouseOverCell(calendarSelection: CalendarSelection, dayIndex: number, roomIndex: number, bbRtId?: number) {
    if (calendarSelection.clicked || calendarSelection.clickHold) {
      calendarSelection.calendarSelection = JSON.parse(JSON.stringify(calendarSelection.resetCalendarSelection));
      calendarSelection.endXIndex = dayIndex;
      calendarSelection.endYIndex = roomIndex;

      const startYPoint = Math.min(calendarSelection.startYIndex, roomIndex);
      const endYPoint = Math.max(calendarSelection.startYIndex, roomIndex);
      const startXPoint = Math.min(calendarSelection.startXIndex, dayIndex);
      const endXPoint = Math.max(calendarSelection.startXIndex, dayIndex);

      let rooms = this.roomTypeMode && bbRtId > -1 ? this.calendarData.roomTypes.get(bbRtId + '').rooms : this.calendarData.rooms;

      const tempRooms = [];
      for (let startRoom = startYPoint; startRoom <= endYPoint; startRoom++) {
        tempRooms.push(rooms[startRoom].bbRoomId);
      }
      calendarSelection.selectedRooms = tempRooms;

      for (let x = startXPoint; x <= endXPoint; x++) {
        for (const y of calendarSelection.selectedRooms) {
          const indexForRoom = rooms.findIndex(room => room.bbRoomId === y);
          if (calendarSelection.selectedRooms.find((element) => element === rooms[indexForRoom].bbRoomId) >= 0) {
            calendarSelection.calendarSelection[indexForRoom][x].selected = true;
          }
        }
      }
    }
  }

  selectCellMouseDown(calendarSelection: CalendarSelection, $event: MouseEvent, dayIndex: number, roomIndex: number, date, room) {
    let booking = this.getBooking(date, room);
    if (booking) {
      if (this.networkService.getStatusOnline === 0) {
        this.networkService.checkNetworkStatus();
        return;
      }
      if (!this.isMobile) {
        this.openBooking($event, booking[0], room.bbRoomId);
      }
    } else if (!calendarSelection.clickHold && !calendarSelection.clicked) {
      if (this.roomTypeMode) {
        let index = 0;
        for (let roomTypeId of Array.from(this.calendarData.roomTypes.keys())) {
          this.resetSelectedCells(index);
          index++;
        }
      } else {
        this.resetSelectedCells();
      }
      calendarSelection.startXIndex = dayIndex;
      calendarSelection.startYIndex = roomIndex;
      calendarSelection.selectedRooms = [roomIndex];
      calendarSelection.clickHold = true;
    }
  }

  selectCellMouseUp(calendarSelection: CalendarSelection, $event: MouseEvent, dayIndex: number, roomIndex: number, bbRtId?: number) {
    if (calendarSelection.clickHold) {
      if (calendarSelection.startXIndex === dayIndex && calendarSelection.startYIndex === roomIndex) {
        calendarSelection.clicked = true;
        calendarSelection.clickHold = false;
      } else {
        this.mouseOverCell(calendarSelection, dayIndex, roomIndex, bbRtId);
        calendarSelection.clicked = false;
        calendarSelection.clickHold = false;
      }
    } else {
      this.mouseOverCell(calendarSelection, dayIndex, roomIndex, bbRtId);
      calendarSelection.clicked = false;
      calendarSelection.clickHold = false;
    }
  }

  isCellSelected(calendarSelection: CalendarSelection, dayIndex: number, roomIndex: number) {
    return calendarSelection?.startXIndex !== undefined
      && calendarSelection?.endXIndex !== undefined
      && calendarSelection?.calendarSelection?.[roomIndex]?.[dayIndex]?.selected;
  }

  selectAllDayMouseDown($event: MouseEvent, dayIndex: number) {
    this.resetSelectedCells();
    this.calendarRoomSelection.clicked = false;
    this.calendarRoomSelection.clickHold = true;
    this.calendarRoomSelection.startXIndex = dayIndex;
    this.calendarRoomSelection.startYIndex = 0;
    this.calendarRoomSelection.endXIndex = dayIndex;
    this.calendarRoomSelection.endYIndex = this.calendarData.rooms.length - 1;

    if (!this.calendarRoomSelection.selectedRooms || this.calendarRoomSelection.selectedRooms.length === 0) {
      this.calendarRoomSelection.selectedRooms = [];
      this.calendarRoomSelection.selectedRooms.push(this.calendarData.rooms[0].bbRoomId);
    }

    for (const y of this.calendarRoomSelection.selectedRooms) {
      const indexForRoom = this.calendarData.rooms.findIndex(room => room.bbRoomId === y);
      if (indexForRoom > -1 && this.calendarRoomSelection.selectedRooms.find(bbRoomId =>
        bbRoomId === this.calendarData.rooms[indexForRoom].bbRoomId)) {
        this.calendarRoomSelection.calendarSelection[indexForRoom][dayIndex].selected = true;
      }
    }
  }

  selectAllDayMouseUp($event: MouseEvent, dayIndex: number) {
    this.calendarRoomSelection.endXIndex = dayIndex;
    this.calendarRoomSelection.clicked = false;
    this.calendarRoomSelection.clickHold = false;

    const startXPoint = Math.min(this.calendarRoomSelection.startXIndex, dayIndex);
    const endXPoint = Math.max(this.calendarRoomSelection.startXIndex, dayIndex);

    for (let x = startXPoint; x <= endXPoint; x++) {
      for (const y of this.calendarRoomSelection.selectedRooms) {
        const indexForRoom = this.calendarData.rooms.findIndex(room => room.bbRoomId === y);
        if (indexForRoom > -1 && this.calendarRoomSelection.selectedRooms.find(bbRoomId => bbRoomId
          === this.calendarData.rooms[indexForRoom].bbRoomId)) {
          this.calendarRoomSelection.calendarSelection[indexForRoom][x].selected = true;
        }
      }
    }
  }

  selectAllDayMouseOver($even: MouseEvent, dayIndex: number) {
    if (this.calendarRoomSelection.clickHold) {
      const startXPoint = Math.min(this.calendarRoomSelection.startXIndex, dayIndex);
      const endXPoint = Math.max(this.calendarRoomSelection.startXIndex, dayIndex);

      this.calendarRoomSelection.startXIndex = startXPoint;
      this.calendarRoomSelection.endXIndex = endXPoint;
      for (let x = this.calendarRoomSelection.startXIndex; x <= this.calendarRoomSelection.endXIndex; x++) {
        for (const y of this.calendarRoomSelection.selectedRooms) {
          const indexForRoom = this.calendarData.rooms.findIndex(room => room.bbRoomId === y);
          if (indexForRoom > -1 && this.calendarRoomSelection.selectedRooms.find(bbRoomId =>
            bbRoomId === this.calendarData.rooms[indexForRoom].bbRoomId)) {
            this.calendarRoomSelection.calendarSelection[indexForRoom][x].selected = true;
          }
        }
      }
    }
  }

  // This function is commented out due to inactivity. It can come back.
  selectRoom($event: MouseEvent, roomIndex: number) {

    this.initiateSelectedRoomsArray();
    this.initiateSelectedData();

    if (this.calendarRoomSelection.selectedRooms.find(bbRoomId => bbRoomId === this.calendarData.rooms[roomIndex].bbRoomId) > -1) {
      const index = this.calendarRoomSelection.selectedRooms.indexOf(this.calendarData.rooms[roomIndex].bbRoomId, 0);
      if (index > -1) {
        this.calendarRoomSelection.selectedRooms.splice(index, 1);
        for (let x = this.calendarRoomSelection.startXIndex; x <= this.calendarRoomSelection.endXIndex; x++) {
          this.calendarRoomSelection.calendarSelection[roomIndex][x].selected = false;
        }
      }
    } else {

      this.calendarRoomSelection.selectedRooms.push(this.calendarData.rooms[roomIndex].bbRoomId);

      for (let x = this.calendarRoomSelection.startXIndex; x <= this.calendarRoomSelection.endXIndex; x++) {
        if (this.calendarRoomSelection.selectedRooms.find(bbRoomId => bbRoomId === this.calendarData.rooms[roomIndex].bbRoomId)) {
          this.calendarRoomSelection.calendarSelection[roomIndex][x].selected = true;
        }
      }
    }

  }

  cancelSelection(highlightedRoomTypeIndex?) {
    if (this.roomTypeMode) {

      let highlightedCalendarSelection: CalendarSelection = this.calendarRoomTypeSelection[highlightedRoomTypeIndex];
      if (highlightedCalendarSelection && (highlightedCalendarSelection.clicked || highlightedCalendarSelection.clickHold)) {
        for (let calendarSelection of this.calendarRoomTypeSelection) {
          calendarSelection.calendarSelection = JSON.parse(JSON.stringify(calendarSelection.previousCalendarSelection));
          calendarSelection.startXIndex = calendarSelection.previousStartXIndex;
          calendarSelection.endXIndex = calendarSelection.previousEndXIndex;
          calendarSelection.clicked = false;
          calendarSelection.clickHold = false;
        }
      }
    } else {
      let calendarSelection: CalendarSelection = this.calendarRoomSelection;
      if (calendarSelection.clicked || calendarSelection.clickHold) {
        calendarSelection.calendarSelection = JSON.parse(JSON.stringify(calendarSelection.previousCalendarSelection));
        calendarSelection.clicked = false;
        calendarSelection.clickHold = false;
      }
    }

  }

  getBookingStatusStyle(booking: CalendarBooking, bbRoomId: number) {

    let bookingRoom = booking.rooms.find(room => room.bbRoomId === bbRoomId);

    let backgroundColour = this.getBookingStatusColour(booking.status, bookingRoom.checkedIn, bookingRoom.checkedOut);

    return {
      'background-color': backgroundColour.outline
    };
  }

  viewDetails($event: MouseEvent, calendarBooking: CalendarBooking, bbRoomId?, bottomSheetContent?) {
    if (this.isMobile()) {
      this.openBottomSheet(bottomSheetContent);
      return;
    }

    if (this.currentPopover) {
      this.currentPopover.close();
    }

    this.openBooking($event, calendarBooking, bbRoomId);
  }

  openBooking($event: MouseEvent, calendarBooking: CalendarBooking, bbRoomId?) {
    if (this.networkService.getStatusOnline === 0) {
      this.networkService.checkNetworkStatus();
      return;
    }
    $event.stopPropagation();
    $event.preventDefault();

    const roomIndex = calendarBooking.rooms.findIndex(room => room.bbRoomId === bbRoomId);
    const guestId = calendarBooking.rooms[roomIndex].guestId;

    this.pageRouteService.navigate('/calendar', {}, ['/booking-summary/' + calendarBooking.bookingId], {
      bookingid: calendarBooking.bookingId,
      guestid: guestId
    });
  }

  keepPopoverOpen() {
    clearTimeout(this.popoverTimer);
  }

  clearPopoverTimeout() {
    clearTimeout(this.popoverTimer);
    this.popoverTimer = setTimeout(() => {
      if (this.currentPopover) {
        this.currentPopover.close();
      }
    }, 1000);
  }

  popperOptions = (options: Partial<Options>) => {
    options.modifiers.push({
      name: 'preventWindowOverflow',
      enabled: true,
      phase: 'main',
      fn: ({state}) => {
        if (state.rects.reference.y > window.innerHeight) {
          state.options.placement = 'top';
        }
      },
    });
    return options;
  }

  openPopOver(popover: NgbPopover) {
    clearTimeout(this.popoverTimer);
    this.popoverTimer = setTimeout(() => {
      if (this.currentPopover?.isOpen() && this.currentPopover !== popover) {
        this.currentPopover.close();
      }
      this.currentPopover = popover;
      popover.open();
    }, 1000);
  }

  getBookingAdultsCount(calendarBooking: CalendarBooking, currentRoom: CalendarRoom) {
    const bookingRoom = calendarBooking.rooms.find(room => room.bbRoomId === currentRoom.bbRoomId);
    return bookingRoom.noAdults;
  }

  getBookingChildCount(calendarBooking: CalendarBooking, currentRoom: CalendarRoom) {
    const bookingRoom = calendarBooking.rooms.find(room => room.bbRoomId === currentRoom.bbRoomId);
    return bookingRoom.child1 + bookingRoom.child2;
  }

  getGuestName(calendarBooking: CalendarBooking, currentRoom: CalendarRoom) {

    const bookingRoom = calendarBooking.rooms.find(room => room.bbRoomId === currentRoom.bbRoomId);

    let guestName = "???";

    if (calendarBooking.days > 1) {
      if (bookingRoom.firstName !== '' || bookingRoom.surname !== '') {
        guestName = bookingRoom.firstName + ' ' + bookingRoom.surname + (!!bookingRoom.company ? ' @ ' + bookingRoom.company : '');
      } else {
        guestName = "@" + bookingRoom.company;
      }
    } else if (bookingRoom.surname) {
      guestName = bookingRoom.surname;
    } else if (bookingRoom.firstName) {
      guestName = bookingRoom.firstName;
    } else if (bookingRoom.company) {
      guestName = "@" + bookingRoom.company;
    }

    return guestName;
  }

  getGuestDisplayName(calendarBooking: CalendarBooking, currentRoom: CalendarRoom) {
    const bookingRoom = calendarBooking.rooms.find(room => room.bbRoomId === currentRoom.bbRoomId);

    let guestName = '';

    if (calendarBooking.days < 1) {
      return "???";
    }

    if (bookingRoom.firstName) {
      guestName += bookingRoom.firstName;
    }

    if (bookingRoom.surname) {
      guestName === '' ? guestName = bookingRoom.surname : guestName += " " + bookingRoom.surname;
    }

    return guestName;
  }

  roomTypeSelected(bbRtId) {
    return this.getRoomType(bbRtId).selected;
  }

  handleReturnData() {
    if (!this.returnData || !this.pageData?.onClose) {
      return;
    }

    const openBooking = (data) => {
      this.networkService.checkNetworkStatus();
      return;
      if (data.bookingid && data.bZeroRates) {
        const bookingid = data.bookingid;
        const bZeroRates = data.bZeroRates;

        this.pageRouteService.navigate('/calendar', {}, ['/booking-summary/' + bookingid], {
          bookingid: bookingid,
          bZeroRates: bZeroRates,
        });
      } else if (data.close) {
        this.refreshSelection = false;
      }
    };

    // find out from which function we were redirected
    let onClose;
    switch (this.pageData.onClose) {
      case 'openBooking':
        onClose = openBooking;
        break;
    }

    if (onClose) {
      onClose.bind(this)(this.returnData);
    }
  }

  getRoomType(roomTypeId: number) {
    return this.calendarData.roomTypes.get(roomTypeId + '');
  }

  getBookingBodyStyle(booking: CalendarBooking, bbRoomId: number) {

    let bookingRoom = booking.rooms.find(room => room.bbRoomId === bbRoomId);

    let backgroundColour = this.getBookingStatusColour(booking.status, bookingRoom.checkedIn, bookingRoom.checkedOut);

    return {
      'background-color': backgroundColour.fill,
      'border-top': '1px solid ' + backgroundColour.outline,
      'border-bottom': '1px solid ' + backgroundColour.outline,
      'border-right': '1px solid ' + backgroundColour.outline
    }

    /**/
  }

  getBookingStatusColour(status: string, checkedIn: boolean, checkedOut: boolean) {
    let colours: string[];

    switch (status.toLowerCase()) {
      case 'w': //waiting for deposit
        colours = ['#00a3d8', '#a7e9ff'];
        break;
      case 'c':
        if (checkedIn) {
          colours = ['#ffd659', CalendarComponent.hexToRGB('#ffd659')];
          break;
        } else if (checkedOut) {
          colours = ['#fff299', CalendarComponent.hexToRGB('#fff299')];
          break;
        } else {
          colours = ['#103783', '#9ebbf3'];
          break;
        }
      case 's':
        colours = ['#008843', '#a9eed0'];
        break;
      case 'r': //reserved
        colours = ['#b239f7', '#e6c9ff'];
        break;
      case 'u': //unavailable
        colours = ['#a6acaf', '#eaeaea'];
        break;
      case 'p': //provisional
        colours = ['#f739df', '#fecbff'];
        break;
      case 'o': //outstanding
        colours = ['#ab2930', '#f3cfd1'];
        break;
      default:
        colours = ['#fff', '#000']
    }

    return {
      outline: colours[0],
      fill: colours[1],
    }
  }

  getRoomTypeAvailability(roomType: CalendarRoomType, day) {
    let availability = 0;

    for (let room of roomType.rooms) {
      if (!this.getBookingForAvailability(day, room)) {
        availability++;
      }
    }

    return availability;
  }

  isCloseOutValidDay(validdays, dayOfWeek) {
    let adjustedDayOfWeek = dayOfWeek;

    //Sunday is index 6 from NB side. (E.g M-WT-SS)
    if (adjustedDayOfWeek === 0) {
      adjustedDayOfWeek = adjustedDayOfWeek + 6;
    } else {
      adjustedDayOfWeek = adjustedDayOfWeek - 1;
    }

    return validdays[adjustedDayOfWeek] !== '-';
  }

  getRoomTypeCLoseOut(bbRtId, day) {
    let hasCloseOut = false;
    let dayView = new Date(new Date(day).setHours(0, 0, 0, 0));
    let dayOfWeek = new Date(day).getDay();
    this.closeOutToolTipMap = {};

    //Loop over close out data and match the specific room type id
    for (let closeOut of this.closeouts) {

      if (closeOut.bbrtid === 0
        && dayView >= new Date(new Date(closeOut.startdate).setHours(0, 0, 0, 0))
        && dayView <= new Date(new Date(closeOut.enddate).setHours(0, 0, 0, 0))
        && this.isCloseOutValidDay(closeOut.validdays, dayOfWeek)) {

        this.closeOutType = closeOut.type;
        this.closeOutToolTipMap[closeOut.type] = closeOut.type;
        hasCloseOut = true;
      } else if (bbRtId === closeOut.bbrtid
        && dayView >= new Date(new Date(closeOut.startdate).setHours(0, 0, 0, 0))
        && dayView <= new Date(new Date(closeOut.enddate).setHours(0, 0, 0, 0))
        && this.isCloseOutValidDay(closeOut.validdays, dayOfWeek)) {

        this.closeOutType = closeOut.type;
        this.closeOutToolTipMap[closeOut.type] = closeOut.type;
        hasCloseOut = true;
      }
    }

    return hasCloseOut;
  }

  getWidth(booking: Array<CalendarBooking>) {
    const tzoffset = this.calendar.start.getTimezoneOffset() * 60000;
    const startDate = new Date(this.calendar.start.getTime() - tzoffset);

    let isBookingStartDateBeforeDisplayDate = moment(booking[0].fromDate).diff(startDate.toISOString().substring(0, 10), 'days') < 0;

    let displayNights = isBookingStartDateBeforeDisplayDate ?
      this.datelibraryService.getDifferenceBetweenDates(startDate.toISOString().substring(0, 10), booking[0].toDate) :
      booking[0].days;

    return ((this.gridSize.dayWidth * +displayNights) - 2) + 'px';
  }

  selectRoomType(bbRtId: number) {
    let isRoomTypeOpen = this.calendarService.getRoomTypeOpenStatusData.get(bbRtId + "");
    this.calendarService.getRoomTypeOpenStatusData.set(bbRtId + '', !isRoomTypeOpen);
  }

  get getRoomTypes() {
    return this.calendarData.roomTypeValues;
  }

  getCalendarSelection(roomTypeIndex?: number) {
    return this.roomTypeMode ? this.calendarRoomTypeSelection[roomTypeIndex ?? 0] : this.calendarRoomSelection;
  }

  processWebsocketData(data) {
    let routePath = this.router.routerState.snapshot.url;
    let bRefreshGrid = false;
    if (data.action === "PROVISIONAL" && routePath === "/calendar") {
      const provisionalData = data as WebsocketProvisionalData;
      const provisionalBookingText = provisionalData.guestname ? 'provisionalBooking' : 'provisionalBookingReminder';

      this.confirmationService.show({
        title: 'provisionalHeading',
        text: this.translateService.translateWithVariables('calendar', provisionalBookingText, {
          guestname: provisionalData.guestname,
          arrivaldate: provisionalData.arrivaldate && formatDate(provisionalData.arrivaldate, "dd MMM yyyy", "en-GB")
        }),
        buttons: [{
          text: 'no',
          class: 'btn__warning',
          callback: () => {
          }
        }, {
          text: 'yes',
          class: 'btn__green',
          callback: () => {
            this.pageRouteService.navigate('/calendar', {}, ['/booking-summary/' + data.bookingid], {
              bookingid: provisionalData.bookingid,
            });
          }
        }]
      });
    }

    if (data.action === "RESERVED_WITH_AUTO_EXPIRE") {
      //TODO we need to create pop up to notify cancel reserved booking.
      /*
      this.confirmationService.show({
        title: 'alertReported',
        text: "The following provisional booking has expired - " + data.bookingid,
        buttons: [{
          text: 'ok',
          callback: () => {}
        }]
      });
      */
    }

    if (data.action === "CALENDAR_REFRESH") {
      bRefreshGrid = true;
    }

    if (data.action === "BOOKING_NOTIFICATION") {
      //If all the data is present, do the notification
      if (data.clientname && data.allocatedrooms && data.bookingdate) {
        this.bookingNotificationService.addNotification({
          bookingdate: this.datelibraryService.getLocalDate(data.bookingdate),
          bookingid: data.bookingid,
          clientname: data.clientname,
          allocatedrooms: data.allocatedrooms,
          fromdate: this.datelibraryService.getLocalDate(data.fromdate)
        });
      }
      bRefreshGrid = true;
    }

    if (bRefreshGrid) {
      this.refreshGrid(true);
    }
  }

  manageProperties() {
    if (this.networkService.getStatusOnline === 0) {
      this.networkService.checkNetworkStatus();
      return;
    }
    this.pageRouteService.navigate('/calendar', {}, ['/my-properties'], {bbid: this.bbid});
  }

  getLocalDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.debounce(() => {
      this.calendarService.refreshDayCount();
      this.refreshGrid(true);
    })();
  }

  getRoomTypeTooltip() {
    const roomType = this.roomTypeMode ? this.translateService.translate('calendar', 'physicalRoom') :
      this.translateService.translate('calendar', 'roomType');
    return this.translateService.translateWithVariables('calendar', 'roomSwitch', {'roomType': roomType})
  }

  getCloseOutTooltip() {

    if (this.closeOutToolTipMap[1]) {
      return this.translateService.translate('calendar', 'fullCloseOut');
    } else if (this.closeOutToolTipMap[2]) {
      return this.translateService.translate('calendar', 'closedForArrivalAndDeparture');
    } else if (this.closeOutToolTipMap[3] && this.closeOutToolTipMap[4]) {
      return this.translateService.translate('calendar', 'closedForArrivalAndDeparture');
    } else if (this.closeOutToolTipMap[3]) {
      return this.translateService.translate('calendar', 'closedForArrival');
    } else if (this.closeOutToolTipMap[4]) {
      return this.translateService.translate('calendar', 'closedForDeparture');
    } else {
      return "";
    }
  }

  getRoomBooking(rooms: Array<BookingRoom>, room: CalendarRoom) {
    return rooms.find(roomBooking => roomBooking.bbRoomId === room.bbRoomId);
  }

  getBookingGuestName(rooms: Array<BookingRoom>, room: CalendarRoom) {
    const roomBooking = this.getRoomBooking(rooms, room);
    return roomBooking.title + ' ' + roomBooking.firstName + ' ' + roomBooking.surname + (roomBooking.company !== undefined && roomBooking.company.length > 0 ? ' @ ' + roomBooking.company : '');
  }

  private getCalendarDateRangeOrDefault(roomsSelected, fromdate, todate) {
    const from = roomsSelected.length > 0 ? fromdate : this.datelibraryService.getDate();
    const to = roomsSelected.length > 0 ? todate :
      this.datelibraryService.getDate(this.datelibraryService.addDaysTwo(from, 1));
    return {from, to};
  }

  private getFromCalendarRoomTypeSelection() {
    let fromdate, todate;
    let roomsSelected = this.getSelectedRooms();

    let selectedRoomTypeIndex = this.calendarRoomTypeSelection.findIndex(calendarRoomTypeSelection => {
      return calendarRoomTypeSelection.selectedRooms.length > 0;
    });

    if (selectedRoomTypeIndex > -1) {
      const calendarRoomTypeSelection = this.calendarRoomTypeSelection[selectedRoomTypeIndex];
      fromdate = this.dates[calendarRoomTypeSelection.startXIndex].date;
      todate = this.datelibraryService.addDaysTwo(this.dates[calendarRoomTypeSelection.endXIndex].date, 1);

      const selectedRoomType = Array.from(this.calendarData.roomTypes.values())[selectedRoomTypeIndex];
      roomsSelected = this.getSelectedRooms(selectedRoomType, selectedRoomTypeIndex);
    } else {
      fromdate = new Date(this.calendar.start);
      todate = this.datelibraryService.addDaysTwo(fromdate, 1);
    }

    return {
      fromdate,
      todate,
      roomsSelected
    }
  }

  private getFromCalendarRoomSelection() {
    const {startXIndex, endXIndex} = this.calendarRoomSelection;
    const [minXIndex, maxXIndex] = [startXIndex, endXIndex].sort((a, b) => a - b);

    if (this.dates[minXIndex] === undefined || this.dates[maxXIndex] === undefined) {
      console.error('Invalid date index in calendarRoomSelection');
      return null;
    }

    const fromdate = this.dates[minXIndex].date;
    const todate = this.datelibraryService.date(this.dates[maxXIndex].date).add(1, "day").toDate();

    return {
      fromdate,
      todate
    }
  }

  private refreshBookingData() {
    this.availabilityBookingData = {};
    const bookingsPerDay = {};

    const tzoffset = this.calendar.start.getTimezoneOffset() * 60000;
    const startDate = new Date(this.calendar.start.getTime() - tzoffset);
    for (const booking of this.calendarData.bookings) {
      let bookingDisplayFromDate = moment(booking.fromDate).diff(startDate.toISOString().substring(0, 10), 'days') > 0 ?
        booking.fromDate :
        moment(startDate.toISOString().substring(0, 10)).format('YYYY-MM-DD');

      const roomBooking = !!bookingsPerDay[bookingDisplayFromDate] ? bookingsPerDay[bookingDisplayFromDate] : new CalendarBooking();

      const roomBookingDeparture = !!this.availabilityBookingData[CalendarComponent.getAvailabilityBookingKey(bookingDisplayFromDate, booking.toDate)] ?
        this.availabilityBookingData[CalendarComponent.getAvailabilityBookingKey(bookingDisplayFromDate, booking.toDate)] : new CalendarBooking();

      for (const room of booking.rooms) {
        roomBooking[room.bbRoomId + ''] = !!roomBooking[room.bbRoomId + ''] ? roomBooking[room.bbRoomId + ''] : [];
        roomBooking[room.bbRoomId + ''].push(booking);
        bookingsPerDay[bookingDisplayFromDate] = roomBooking;

        roomBookingDeparture[room.bbRoomId + ''] = !!roomBookingDeparture[room.bbRoomId + ''] ? roomBookingDeparture[room.bbRoomId + ''] : [];
        roomBookingDeparture[room.bbRoomId + ''].push(booking);
        this.availabilityBookingData[CalendarComponent.getAvailabilityBookingKey(bookingDisplayFromDate, booking.toDate)] = roomBookingDeparture;
      }
    }

    return bookingsPerDay;
  }

  private static getAvailabilityBookingKey(fromDate, toDate) {
    return fromDate + '/' + toDate;
  }

  // this populates 2D matrix grid data
  private populateCalendarSelectionMatrix(rooms: Array<CalendarRoom>): CalendarSelection {
    const calendarSelection: Array<Array<CalendarSelectionCell>> = [];

    const xCount = this.dates.length;
    const yCount = rooms.length;

    for (let y = 0; y < yCount; y++) {
      const xData: Array<CalendarSelectionCell> = [];
      for (let x = 0; x < xCount; x++) {
        xData.push({
          selected: false,
          xIndex: x,
          yIndex: y
        });
      }
      calendarSelection.push(xData);
    }

    return {
      calendarSelection: calendarSelection,
      resetCalendarSelection: JSON.parse(JSON.stringify(calendarSelection)),
      previousCalendarSelection: JSON.parse(JSON.stringify(calendarSelection)),
      selectedRooms: [],
      startXIndex: undefined,
      previousStartXIndex: undefined,
      startYIndex: undefined,
      endXIndex: undefined,
      previousEndXIndex: undefined,
      endYIndex: undefined,
      clicked: false,
      clickHold: false,
      clickRoom: false,
      clickDate: false
    };
  }

  private resetSelectedCells(roomTypeIndex?) {
    let calendarSelection: CalendarSelection = this.getCalendarSelection(roomTypeIndex);
    if (!calendarSelection) {
      return;
    }

    calendarSelection.previousCalendarSelection = JSON.parse(JSON.stringify(calendarSelection.calendarSelection));
    calendarSelection.previousStartXIndex = calendarSelection.startXIndex;
    calendarSelection.previousEndXIndex = calendarSelection.endXIndex;

    calendarSelection.startXIndex = undefined;
    calendarSelection.startYIndex = undefined;
    calendarSelection.endXIndex = undefined;
    calendarSelection.endYIndex = undefined;
    calendarSelection.selectedRooms = [];
    calendarSelection.calendarSelection = JSON.parse(JSON.stringify(calendarSelection.resetCalendarSelection));
  }

  private initiateSelectedData() {
    if (!this.calendarRoomSelection.startXIndex && !this.calendarRoomSelection.endXIndex) {
      this.calendarRoomSelection.startXIndex = 0;
      this.calendarRoomSelection.endXIndex = 0;
    }
  }

  private initiateSelectedRoomsArray() {
    if (!this.calendarRoomSelection.selectedRooms || this.calendarRoomSelection.selectedRooms.length === 0) {
      this.calendarRoomSelection.selectedRooms = [];
    }
  }

  private extractData(response: any) {
    this.calendarData = new Calendar();

    this.calendarData.bookings = [];

    for (const booking of response.data.bookings) {

      const calendarBooking = new CalendarBooking();
      calendarBooking.bookingId = booking.bookingid;
      calendarBooking.bbBookingId = booking.bbbookingid;
      calendarBooking.fromDate = booking.fromdate;
      calendarBooking.toDate = booking.todate;
      calendarBooking.days = booking.days ? booking.days : moment(booking.todate).diff(moment(booking.fromdate), 'days'); // does not exist todo
      calendarBooking.status = booking.status;
      calendarBooking.source = booking.source ? booking.source : ''; // todo current no source is defined in the API, should change the API
      calendarBooking.note = booking.notes;
      calendarBooking.madebytext = booking.madebytext;
      calendarBooking.madebyemail = booking.madebyemail;
      calendarBooking.madebyphoneno = booking.madebyphoneno;
      calendarBooking.rooms = [];
      for (const room of booking.rooms) {
        const bookingRoom = new BookingRoom();
        bookingRoom.bookingId = booking.bookingid;
        bookingRoom.bbRoomId = room.bbroomid;
        bookingRoom.bbRtId = room.bbrtid;
        bookingRoom.bbRateId = room.bbrateid;
        bookingRoom.title = room.title;
        bookingRoom.firstName = room.firstname;
        bookingRoom.surname = room.surname;
        bookingRoom.company = room.company;
        bookingRoom.noAdults = room.noadults;
        bookingRoom.child1 = room.child1;
        bookingRoom.child2 = room.child2;
        bookingRoom.guestId = room.guestid;
        bookingRoom.bbGuestId = room.bbguestid;
        bookingRoom.checkedIn = room.checkedin;
        bookingRoom.checkedOut = room.checkedout;
        calendarBooking.rooms.push(bookingRoom);
      }

      this.calendarData.bookings.push(calendarBooking);
    }

    this.calendarData.rooms = [];
    this.calendarData.roomTypes = new Map<string, CalendarRoomType>();

    const bbRoomType = new CalendarRoomType();
    bbRoomType.bbRtId = 0;
    bbRoomType.orderBy = 0;
    bbRoomType.rtName = "No Room Type";
    bbRoomType.rooms = [];
    this.calendarData.roomTypes.set('0', bbRoomType);

    for (const bbRtId in response.data.roomtypes) {
      if (response.data.roomtypes.hasOwnProperty(bbRtId)) {

        if (!this.calendarData.roomTypes.has(bbRtId)) {
          const roomtype = response.data.roomtypes[bbRtId];

          const bbRoomType = new CalendarRoomType();
          bbRoomType.bbRtId = roomtype.bbrtid;
          bbRoomType.orderBy = roomtype.orderby;
          bbRoomType.rtName = roomtype.rtname;
          bbRoomType.rooms = [];

          let roomTypeOpenStatus = this.calendarService.getRoomTypeOpenStatusData.get(roomtype.bbrtid + '');
          this.calendarService.getRoomTypeOpenStatusData.set(roomtype.bbrtid + '', !!roomTypeOpenStatus);
          this.calendarData.roomTypes.set(roomtype.bbrtid + '', bbRoomType);
        }
      }
    }

    for (const room of response.data.rooms) {
      const bbRoom = new CalendarRoom();
      bbRoom.roomName = room.roomname;
      bbRoom.bbRoomId = room.bbroomid;
      bbRoom.bbRtId = room.bbrtid;
      bbRoom.orderBy = room.orderby;
      bbRoom.roomType = this.calendarData.roomTypes.get(room.bbrtid + '').rtName;
      this.calendarData.rooms.push(bbRoom);
      this.calendarData.roomTypes.get(room.bbrtid + '').rooms.push(bbRoom);
    }
    this.calendarData.rooms = this.calendarData.rooms.sort((roomA, roomB) => (roomA.orderBy < roomB.orderBy ? -1 : 1));
    if (this.calendarData.roomTypes.get('0').rooms.length === 0) {
      this.calendarData.roomTypes.delete('0');
    }
    this.calendarData.roomTypeValues = Array.from(this.calendarData.roomTypes.values());
    this.calendarData.roomTypeValues = this.calendarData.roomTypeValues.sort((roomA, roomB) => (roomA.orderBy < roomB.orderBy ? -1 : 1));
  }

  private static hexToRGB(h) {
    let r, g, b;

    // 3 digits
    if (h.length == 4) {
      r = "0x" + h[1] + h[1];
      g = "0x" + h[2] + h[2];
      b = "0x" + h[3] + h[3];

      // 6 digits
    } else if (h.length == 7) {
      r = "0x" + h[1] + h[2];
      g = "0x" + h[3] + h[4];
      b = "0x" + h[5] + h[6];
    }

    return "rgba(" + +r + "," + +g + "," + +b + ", 0.2)";
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
