<div class="container">
  <div class="d-flex flex-column gap-3 m-3">
    <section class="d-flex flex-column flex-sm-row gap-2">
      <span class="flex-1">Invoice To:</span>
      <div class="d-flex flex-row flex-3 gap-3">
        <cui-input type="radio" name="invoiceToRadio" label="Main Account" [(ngModel)]="extra.bbaccountid"
          classes="fs-14" (ngModelChange)="invoiceToChange(true)" [disabled]="accountinformation.bookingbbaccountclosed"
          [selected]="accountinformation.bookingbbaccountid"></cui-input>

        <cui-input type="radio" name="invoiceToRadio" label="Guest Account" [(ngModel)]="extra.bbaccountid"
          classes="fs-14" (ngModelChange)="invoiceToChange(false)" [selected]="accountinformation.guestbbaccountid">
        </cui-input>
      </div>
    </section>

    <div class="d-flex flex-column gap-3" [formGroup]="extraForm">
      <section class="d-flex flex-1 flex-column flex-sm-row gap-3">
        <cui-date-selector [viewMode]="2" label="Date" formControlName="extradate" dateText="Date" class="flex-1"
          [iconColor]="'color-mono-primary-text'"> </cui-date-selector>
        <cui-input type="select" class="w-100 status-input flex-1" [dropdownOptions]="stocklist"
          dropdownOptionsLabel="stockname" dropdownOptionsValue="bbstockid" label="Stock" formControlName="stock"
          [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'" [trailingIconColor]="'color-secondary-text'"
          (change)="updatePrice($event)">
        </cui-input>
      </section>

      <section class="d-flex flex-1 flex-row gap-3">
        <cui-input label="Qty" placeholder="Enter Qty" classes="h-48" formControlName="qty" [sizing]="'md'"
          class="flex-1">
        </cui-input>
        <cui-input label="Price" placeholder="Enter Price" classes="h-48" formControlName="price" [sizing]="'md'" (onBlur)="formatPrice()"
          class="flex-1">
        </cui-input>
      </section>
    </div>
  </div>
</div>
