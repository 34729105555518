import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tap-n-pay-modal-message',
  templateUrl: './tap-n-pay-modal-message.component.html',
  styleUrls: ['./tap-n-pay-modal-message.component.scss']
})
export class TapNPayModalMessageComponent {

  title = 'Tap & Pay';
  message: string | undefined;
  subtitle: string | undefined;
  approveText: string | undefined;
  rejectText: string | undefined;
  primaryText: string | undefined;
  lightText: string | undefined;

  constructor(public activeModal: NgbActiveModal) {}

  accept(){
    this.activeModal.close(true);
  }

  reject() {
    this.activeModal.close(false);
  }

  action() {
    this.activeModal.close();
  }
}
