import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {TranslateService} from 'src/app/service/translate.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {RoomAddService} from 'src/app/service/room-add.service';
import {BookingSummaryService} from "../../../service/booking-summary.service";
import {ToastService} from "@nightsbridge/common-ui/toasts";

@Component({
  selector: 'app-room-add',
  templateUrl: './room-add.component.html',
  styleUrls: ['./room-add.component.scss']
})
export class RoomAddComponent implements OnInit {
  @Input() bookingid;

  pageData;
  returnData;

  @Input() options;

  focusAdults = true;
  selectedRoom;
  availableRooms = [];
  client;
  child1_display;
  child2_display;
  room: { [key: string]: any } = {};
  roomrates;
  bbdetails;
  bbrateid;
  booking;
  roomavailable;
  guest;

  roomAddForm = new FormGroup({
    selectedRoom: new FormControl(undefined), // selectedRoom
    selectedRoomId: new FormControl(undefined), // selectedRoom.bbroomid
    bbroomid: new FormControl(undefined), // selectedRoom.bbroomid
    fullname: new FormControl(undefined), // client.fullname
    noadults: new FormControl(undefined, [Validators.required, Validators.min(0)]), // room.noadults
    child1: new FormControl(undefined, [Validators.required, Validators.min(0)]), // room.child1
    child2: new FormControl(undefined, [Validators.required, Validators.min(0)]), // room.child2
    bbrateid: new FormControl(undefined), // room.bbrateid
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private pageRouteService: PageRouteService,
    private pageRouteDataService: PageRouteDataService,
    private roomAddService: RoomAddService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.pageData = this.pageRouteDataService.getData();
    this.returnData = this.pageRouteDataService.getReturnData();

    if (this.pageData && !this.options) {
      this.options = this.pageData;
    }

    if (!this.options) {
      this.close();
    }

    this.booking = {
      fromdate: this.options.fromdate,
      todate: this.options.todate,
      bookingid: this.options.bookingid,
    };
    this.client = this.options.client;

    this.roomAddForm.valueChanges.subscribe(() => {
      this.debounce(this.getFormValues)();
    });

    this.getData();
  }

  exit() {
    this.pageRouteService.close();
  }

  close(returnData?) {
    this.pageRouteService.back(returnData);
  }

  addRoom() {
    this.roomAddForm.markAllAsTouched();
    if (this.roomAddForm.valid) {
       this.save();
    } else {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('roomAdd', 'validClientName'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    }
  }

  save() {
    this.roomAddService.addRoom(this.booking.bookingid, this.client.clientid, this.room).subscribe((response: any) => {
      this.guest = response.data.guest;
      this.guest.bbrateid = this.room.bbrateid;
      this.close(this.guest);
      this.toastService.show('Successful', 'Booking Saved', {classname: 'toast-success', delay: 4000, positionClass: 'toast-bottom-right'});
    });
  }

  setClient() {
    // TODO check if should go to client-edit
    this.pageRouteService.navigate(`booking-summary/${this.bookingid}/room-add`, {
      ...this.options,
      onClose: 'f_setRoomGuest',
    }, ['/clients/search'], {
      mode: 2,
    });
  }

  getData() {
    this.roomAddService.fetchRoomAdd(this.booking.fromdate, this.booking.todate).subscribe((response: any) => {
      if (response.data.rooms && response.data.rooms.length === 0) {
        this.confirmationService.show({
          title: 'confirmation',
          text: 'No rooms available to add',
          buttons: [{
            text: 'ok',
            callback: () => {
            }
          }]
        });
        this.roomavailable = false;
      } else {
        this.roomavailable = true;
        this.bbdetails = response.data.bbdetails;
        this.child1_display = this.translateService.translate('roomAdd', 'child') + ' (' + this.bbdetails.childlowerlimit + '-' + this.bbdetails.childage1 + ')';
        if (this.bbdetails.childage2 > 0) {
          this.child2_display = this.translateService.translate('roomAdd', 'child') + ' (' + (this.bbdetails.childage1 + 1) + '-' + this.bbdetails.childage2 + ')';
        } else {
          this.child2_display = undefined;
        }

        this.availableRooms = response.data.rooms;
        this.selectedRoom = this.availableRooms[0]; // Default
        this.roomrates = this.selectedRoom.roomrates;
        this.bbrateid = this.roomrates[0].bbrateid; // Should be default

        this.room.bbroomid = this.selectedRoom.bbroomid;
        this.room.noadults = this.bbdetails.defaultguest;
        this.room.child1 = 0;
        this.room.child2 = 0;
        this.room.bbrateid = this.selectedRoom.defaultrateid;
      }

      this.setFormValues();

      this.handleReturnData();

    });
  }

  updateMealPlans(): void {
    const selectedRoomId = this.roomAddForm.get('selectedRoomId')?.value;
    const selectedRoom = selectedRoomId
      ? this.availableRooms.find(room => room.bbroomid === Number(selectedRoomId))
      : this.roomAddForm.get('selectedRoom')?.value;

    if (!selectedRoom?.roomrates?.length) {
      return;
    }

    this.roomrates = selectedRoom.roomrates;
    this.room.bbroomid = selectedRoom.bbroomid;
    this.room.bbrateid = selectedRoom.defaultrateid || selectedRoom.roomrates[0].bbrateid;

    this.roomAddForm.patchValue({
      bbroomid: this.room.bbroomid,
      bbrateid: this.room.bbrateid,
      selectedRoom
    });
  }

  setFormValues() {
    this.roomAddForm.patchValue({
      selectedRoom: this.selectedRoom,
      fullname: this.client.fullname,
      noadults: this.room.noadults,
      child1: this.room.child1,
      child2: this.room.child2,
      bbrateid: this.room.bbrateid,
    });
  }

  getFormValues() {
    this.selectedRoom = this.roomAddForm.value.selectedRoom;
    this.client.fullname = this.roomAddForm.value.fullname;
    this.room.noadults = this.roomAddForm.value.noadults;
    this.room.child1 = this.roomAddForm.value.child1;
    this.room.child2 = this.roomAddForm.value.child2;
    this.room.bbrateid = this.roomAddForm.value.bbrateid;
  }

  handleReturnData() {
    if (!this.returnData || !this.pageData?.onClose) {
      return;
    }

    const f_setRoomGuest = (client) => {
      this.client = client;
      // this.show(); // TODO check how this should behave
    };

    // find out from which function we were redirected
    let onClose;
    switch (this.pageData.onClose) {
      case 'f_setRoomGuest':
        onClose = f_setRoomGuest;
        break;
    }

    if (onClose) {
      onClose.bind(this)(this.returnData);
    }
  }

  back() {
    this.close();
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }
}
