<div class="guest-data-card p-3 rounded rounded-3"
  [ngStyle]="{'border': isMobile() ? 'none' : 'solid 1px '+ getStatusColor(booking,room).outline}">
  <div class="d-flex mb-2">
    <div class="me-2 d-flex align-items-center justify-content-center">
      <svg class="ic ic-18">
        <use href="assets/images/sprite.svg#recent-bookings" />
      </svg>
    </div>
    <span>{{booking.fromDate | date:'dd MMM'}} - {{booking.toDate | date:"dd MMM yyyy"}}</span>
  </div>
  <div class="d-flex mb-2" *ngIf="booking.madebyphoneno">
    <div class="me-2 d-flex align-items-center justify-content-center">
      <svg class="ic ic-18">
        <use href="assets/images/sprite.svg#phone" />
      </svg>
    </div>
    <span *ngIf="isMobile()">
      <a class="mail-to" [href]="'tel:'+booking.madebyphoneno">{{booking.madebyphoneno}}</a>
    </span>
    <span *ngIf="!isMobile()">{{booking.madebyphoneno}}</span>
  </div>
  <div class="d-flex mb-2" *ngIf="booking.madebyemail">
    <div class="me-2 d-flex align-items-center justify-content-center">
      <svg class="ic ic-18">
        <use href="assets/images/sprite.svg#email" />
      </svg>
    </div>
    <span><a class="mail-to" [href]="getMailTo(booking)">{{booking.madebyemail}}</a></span>
  </div>
  <div class="d-flex mb-2" *ngIf="booking.source">
    <div class="me-2 d-flex align-items-center justify-content-center">
      <svg class="ic ic-18">
        <use href="assets/images/sprite.svg#booking-source" />
      </svg>
    </div>
    <span>{{booking.source}}</span>
  </div>
  <div class="d-flex gap-1 mt-3">
    <div class="badge rounded-pill text-black border" [ngStyle]="{'background': getStatusColor(booking,room).fill}">
      {{getStatus(booking.status)}}
    </div>
    <div class="badge d-flex align-items-center rounded-pill text-primary bg-white border border-primary"
      *ngIf="getRoomBooking(booking.rooms,room).checkedIn">
      <div class="me-1 d-flex align-items-center justify-content-center">
        <svg class="ic ic-22">
          <use href="assets/images/sprite.svg#check-in" />
        </svg>
      </div>
      Checked-in
    </div>
    <div class="badge d-flex align-items-center rounded-pill text-primary bg-white border border-primary"
      *ngIf="!getRoomBooking(booking.rooms,room).checkedIn && getRoomBooking(booking.rooms,room).checkedOut">
      <div class="me-1 d-flex align-items-center justify-content-center">
        <svg class="ic ic-22">
          <use href="assets/images/sprite.svg#check-out" />
        </svg>
      </div>
      Checked-out
    </div>
  </div>
  <div class="mt-3" *ngIf="!isMobile()">
    <cui-button classes="fs-6 fw-medium lh-21"
      (click)="popover.close(); openBooking($event, booking, room.bbRoomId)">View
      More</cui-button>
  </div>
</div>