<app-tap-n-pay-modal-container [title]="title" [fullWidth]="true">
  <div class="d-flex justify-content-center my-1">
    <svg class="h-100">
      <use href="assets/images/sprite.svg#info-error"/>
    </svg>
  </div>
  <div class="text-center fs-16">
    <div class="p-1 fw-semi-bold" *ngIf="subtitle"> {{subtitle}} </div>
    <div class="wrap-text">
      <div class="white-space-pre-line" [innerHTML]="message"> </div>
    </div>
  </div>
</app-tap-n-pay-modal-container>
<div class="d-flex modal-footer border-top justify-content-between">
  <cui-button class="d-flex flex-1" (onClick)="reject()" *ngIf="rejectText" [label]="rejectText" name="cancel" classes="tap-n-pay-btn-cancel tap-n-pay-btn-action-override" mode="light"></cui-button>
  <cui-button class="d-flex flex-1" (onClick)="accept()" *ngIf="approveText" [label]="approveText" name="refund" classes="tap-n-pay-btn-success tap-n-pay-btn-action-override" mode="light"></cui-button>
  <div class="flex-4 d-none d-sm-flex" *ngIf="!rejectText && !approveText">
    <!-- PLACEHOLDER DIV, DO NOT REMOVE -->
  </div>
  <cui-button class="d-flex flex-1" (onClick)="action()" *ngIf="lightText" [label]="lightText" name="action" classes="tap-n-pay-btn-action-override" mode="light"></cui-button>
  <cui-button class="d-flex flex-1" (onClick)="action()" *ngIf="primaryText" [label]="primaryText" name="action" classes="tap-n-pay-btn-action-override" mode="primary"></cui-button>
</div>
